// Customizable Area Start
import React from "react";
import {
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  Backdrop,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { signatureview, VolumeIcon, CopyICon, ReloadIcon,DislikeIcon } from "./assets";
import styled from '@emotion/styled';
const themes = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

import LandingPageController, {
  Props,
} from "./LandingPageController";
import EmailAccountRegistration from "../../email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import ForgetPassword from "../../forgot-password/src/ForgetPassword.web";
import HeaderTopNavigation from "../../../components/src/HeaderTopNavigation"
import Footerbar from "../../../components/src/Footerbar"

export default class BrainsStromWithJarvis extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }
    render() {
        return (
            <ThemeProvider theme={themes}>
                
             <Box sx={outer}>
               <StyleBrainStormContainer>
                <HeaderTopNavigation  handlelogin={this.handleOpenLogInForm.bind(this)}
                     handlesignup={this.handleOpenSignUpForm.bind(this)} isActive={this.state.isBackgroundVideoAvailable}/>
                
                <Grid  item xs={12} style={{ backgroundColor: '#F6F6F6' }}>

                    <Box style={{display:'flex', justifyContent:'center' ,alignItems:'center',width:'60%',backgroundColor:'#F6F6F6'}} >
                        <Box  > 

                            <StyledJarvisTypo >JARVYS AI</StyledJarvisTypo>
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={12} style={{ backgroundColor: '#F6F6F6' }}  height={'70vh'} overflow={"scroll"}>
                
                {
                    !this.state.values.length?(
                
                    <Box display={'flex'} justifyContent={'center'} alignItems={"center"} marginTop={"100px"}>
                        <Box  padding={'20px'} >
                            <Box >

                                <Box>
                                    <StyledLetsStartTypo >Lets start to talk with JARVYS</StyledLetsStartTypo>
                                </Box>
                                <Box display={'flex'} justifyContent={"space-around"} >
                                    <Box display={"flex"} justifyContent={"center"} alignItems={'center'} flexDirection={"column"}>
                                        <StyledLetsStartChildTypo >You can ask about anything that will help you </StyledLetsStartChildTypo>
                                        <StyledLetsStartChildTypo >improve your business, or find out where to start... </StyledLetsStartChildTypo>
                                    </Box>
                                    <StyledTextLogo src={signatureview} alt="signatureimg" />
                                </Box>
                            </Box>

                        </Box>

                    </Box> 
                    ) :(
                      <Box   width="100%" display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} overflow={'auto'} >
                            {
                              this.state.values.filter(item=>item!=null).map((item,index)=>(
            
                                <Box  width="50%" overflow={"scroll"} key={index}>
                                {index%2===0?(<Box>
                                    <Box marginBottom={"2px"}>
                                    <Typography style={{fontFamily:'Open Sans',fontWeight:700,fontSize:'12px',color:'#000000',marginBottom:'10px'}}>Your question</Typography>
                                    </Box>
                                    <Box>

                                    <Typography style={{lineHeight:'28px',fontFamily:'Open Sans',fontWeight:400,fontSize:'18px',color:'#000000'}}>{item}
                                        
                                    </Typography>
                                    </Box>
                                    <Box display={'flex'} justifyContent={'flex-start'}>
                                        <Box display={"flex"} justifyContent={"space-between"}  width={'14%'}>
                                            
                                         <img src={VolumeIcon} alt="volume"/> <img src={CopyICon} alt="copyicon"/><img src={ReloadIcon} alt="reload"/><img src={DislikeIcon} alt="dislike"/>
                                        </Box>
                                         
                                         </Box>
                                </Box>):
                                
                                (
                                <Box marginTop={'20px'}>
                                <Box marginBottom={'2px'}>
                                    < Typography style={{fontFamily:'Open Sans',fontWeight:700,fontSize:'12px',color:'#000000',marginBottom:'10px'}}>JARVYS’s answer</Typography></Box>
                                <Box >
                               
                                <div
                                  style={{
                                    lineHeight: '28px',
                                    fontFamily: 'Open Sans', 
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    color: '#000000', 
                                  }}
                                  dangerouslySetInnerHTML={{ __html: item }}
                                />
                                </Box>
                            
                                </Box>

                                )}
                            </Box>
                              ))
                            }

                        </Box>)
                                            
                    }
                </Grid>
                <StyleBrainStormParentBoxInput  >
                        <StyleBrainstormInput >

                            <TextField
                                placeholder="Add Information..."
                                multiline
                                fullWidth
                                sx={{
                                    "& fieldset": { border: 'none' },
                                  }}
                                InputProps={{

                                    disableUnderline: true,
                                    style: {
                                        marginLeft: '15px',
                                        flexGrow: 1,
                                        border: 'none',
                                        height: '100%',
                                        color: "#A19E9E",
                                        width: '100%'
                                    },
                                }}
                                value={this.state.userMessage}
                                onChange={this.handleUserMessageChange.bind(this)}
                            />
                        </StyleBrainstormInput>
                        <Button   onClick={this.askToJarvys.bind(this)} data-test-id="goToHomeButton" style={{ backgroundColor: '#202020', borderRadius: '8px', color: '#FFFFFF', fontFamily: 'Open Sans', marginLeft: '15px', padding: '6px 50px', cursor: 'pointer' }}> Ask JARVYS</Button>
                    </StyleBrainStormParentBoxInput>
                     <StyledBelowInputTypo >
                        <Typography  >JARVYS AI can make mistakes. Consider checking important information.</Typography>
                    </StyledBelowInputTypo>
                
                    <Footerbar isActive={this.state.isBackgroundVideoAvailable}/>
                <Backdrop open={this.state.isOpenSignUpForm} onClick={this.handleCloseSignUpForm.bind(this)} data-testid='signUpBackdrop'>
              {this.state.isOpenSignUpForm && <EmailAccountRegistration
                data-testid='signUpForm' 
                id="signUpForm"
                navigation={this.props.navigation}
                />}

            </Backdrop>

            <Backdrop open={this.state.isOpenLogInForm} onClick={this.handleCloseLogInForm.bind(this)} data-testid='loginUpBackdrop'>
              {this.state.isOpenLogInForm && <EmailAccountLoginBlock
                data-testid='logInForm'
                goToRegistration={this.handleOpenRegistrationFromLogIn.bind(this)}
                navigation={this.props.navigation}
                onClose={this.handleCloseLogInForm.bind(this)}
                id="logInForm"
                goToForgetPassword={this.handleOpenForgetPassword.bind(this)}
                />}
            </Backdrop>
            <ForgetPassword
              open={this.state.isOpenForgetPasswordForm}
              navigation={this.props.navigation}
              data-testid='forgetPassword'
              onClose={this.handleCloseForgetPasswordForm.bind(this)}
              goToLoginForm={this.handleOpenLogInForm.bind(this)}
            />
            </StyleBrainStormContainer>
                </Box>
            </ThemeProvider>
        )
    }
}




const StyleBrainStormContainer = styled(Box)({

    height:'100vh',
    width:'100vw',
  
  
      '@media (max-height: 615px)': {
        maxHeight:'82vh',
      },
  
      '@media (max-height: 530px)': {
        maxHeight:'80vh',
      },
      '@media (max-height: 430px)': {
        maxHeight:'76vh',
      },
      '@media (max-height: 350px)': {
        maxHeight:'71vh',
      },
      '@media (max-height: 290px)': {
        maxHeight:'65vh',
      },
      '@media (max-height: 250px)': {
        maxHeight:'50vh',
      },
      '@media (max-height: 200px)': {
        maxHeight:'40vh',
      },
      '@media (max-height: 100px)': {
        maxHeight:'25vh',
      },
 
  });
  
  const outer ={

      
      position:"relative",
      maxHeight:'fit-content',
    width:"fit-content",
  }



  const StyledJarvisTypo=styled(Typography)({
    fontWeight: 600,
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitTextFillColor: 'transparent', marginTop: '10px',marginBottom:'10px',
    fontFamily: 'Open Sans',
    WebkitBackgroundClip: 'text',
    textAlign: 'center',
     fontSize: '40px',
    '@media (max-width: 1238px)': {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        WebkitTextFillColor: 'transparent', marginTop: '20px',marginBottom:'10px',
        background: 'linear-gradient(to right,#0066FF, #E8CC17)',
        WebkitBackgroundClip: 'text',
         fontSize: '50px',
        textAlign: 'center',
        
    
      },
    '@media (max-width: 1034px)': {
        fontWeight: 600,
        background: 'linear-gradient(to right,#0066FF, #E8CC17)',
        fontFamily: 'Open Sans',
        fontSize: '40px',
        WebkitBackgroundClip: 'text',
         WebkitTextFillColor: 'transparent', marginTop: '10px',marginBottom:'10px',
        textAlign: 'center',
    },
    '@media (max-width: 918px)': {
        fontWeight: 600,
        background: 'linear-gradient(to right,#0066FF, #E8CC17)',
        fontFamily: 'Open Sans',
         WebkitTextFillColor: 'transparent', marginTop: '10px',marginBottom:'10px',
         WebkitBackgroundClip: 'text',
         fontSize: '35px',
        textAlign: 'center',
    },
    '@media (max-width: 858px)': {
        fontWeight: 600,
        background: 'linear-gradient(to right,#0066FF, #E8CC17)',
        fontFamily: 'Open Sans',
        fontSize: '32px',
         WebkitTextFillColor: 'transparent', marginTop: '10px',marginBottom:'10px',
         textAlign: 'center',
        WebkitBackgroundClip: 'text',
  
    },'@media (max-width: 768px)': {
        fontWeight: 600,
        background: 'linear-gradient(to right,#0066FF, #E8CC17)',
        fontFamily: 'Open Sans',
        WebkitTextFillColor: 'transparent', marginTop: '10px',marginBottom:'10px',
         WebkitBackgroundClip: 'text',
         fontSize: '32px',
        textAlign: 'center',
  
    },'@media (max-width: 562px)': {
        fontWeight: 600,
        fontFamily: 'Open Sans',
        WebkitTextFillColor: 'transparent', marginTop: '10px',marginBottom:'10px',
        fontSize: '30px',
        background: 'linear-gradient(to right,#0066FF, #E8CC17)',
        WebkitBackgroundClip: 'text',
        textAlign: 'center',
    },
    '@media (max-width: 626px)': {
        fontWeight: 600,
        background: 'linear-gradient(to right,#0066FF, #E8CC17)',
        fontFamily: 'Open Sans',
         WebkitTextFillColor: 'transparent', marginTop: '10px',marginBottom:'10px',
         WebkitBackgroundClip: 'text',
         fontSize: '30px',
        textAlign: 'center',
  
    }, '@media (max-width: 468px)': {
        fontWeight: 600,
        fontFamily: 'Open Sans',
        WebkitTextFillColor: 'transparent', marginTop: '10px',marginBottom:'10px',
        background: 'linear-gradient(to right,#0066FF, #E8CC17)',
        WebkitBackgroundClip: 'text',
         fontSize: '28px',
        textAlign: 'center',
  
    },'@media (max-width: 386px)': {
        fontWeight: 600,
        fontFamily: 'Open Sans',
        background: 'linear-gradient(to right,#0066FF, #E8CC17)',
        WebkitTextFillColor: 'transparent', marginTop: '10px',marginBottom:'10px',
        WebkitBackgroundClip: 'text',
        fontSize: '25px',
        textAlign: 'center',
    },
   
  })


  const StyledLetsStartTypo=styled(Typography)({
    fontWeight: 400, fontSize: '42px',fontFamily: 'Open Sans', color: '#000000',marginBottom: '30px' , padding: '5px',
    '@media (max-width: 1238px)': {
        fontWeight: 400, fontSize: '42px',fontFamily: 'Open Sans', color: '#000000',marginBottom: '30px' , padding: '5px',
        
    
      },
    '@media (max-width: 1034px)': {
        fontWeight: 400,
        padding: '5px',
         fontSize: '40px',fontFamily: 'Open Sans', 
         color: '#000000',marginBottom: '30px' ,
    },
    '@media (max-width: 918px)': {
        fontWeight: 400, fontSize: '35px',fontFamily: 'Open Sans',
        padding: '5px',
         color: '#000000',marginBottom: '30px' , 
    },
    '@media (max-width: 858px)': {
        fontWeight: 400, fontSize: '22px',
        marginBottom: '30px' , padding: '5px',
        fontFamily: 'Open Sans', color: '#000000',
  
    },'@media (max-width: 768px)': {
        fontWeight: 400, fontSize: '20px',fontFamily: 'Open Sans', 
        marginBottom: '30px' , padding: '5px',
        color: '#000000',
  
    },'@media (max-width: 562px)': {
        fontWeight: 400, fontSize: '15px',fontFamily: 'Open Sans', 
        color: '#000000',marginBottom: '30px' ,
         padding: '5px',
    },
    '@media (max-width: 626px)': {
        fontWeight: 400, fontSize: '15px',fontFamily: 'Open Sans',
        padding: '5px',
         color: '#000000',marginBottom: '30px' ,
  
    }, '@media (max-width: 468px)': {
        fontWeight: 400, fontSize: '13px',fontFamily: 'Open Sans', 
        color: '#000000',marginBottom: '30px' , 
        padding: '5px',
  
    },'@media (max-width: 386px)': {
        fontWeight: 400, fontSize: '12px',
        marginBottom: '30px' , padding: '5px',
        fontFamily: 'Open Sans', color: '#000000',
    },
   
  })

  const StyledLetsStartChildTypo=styled(Typography)({
    fontWeight: 400, fontSize: '20px', color: '#000000', fontFamily: 'Open Sans', marginBottom: '5px',
    '@media (max-width: 1238px)': {
        fontWeight: 400, fontSize: '20px', color: '#000000', fontFamily: 'Open Sans', marginBottom: '5px',
        
    
      },
    '@media (max-width: 1034px)': {
        fontWeight: 400, fontSize: '15px', color: '#000000',
         fontFamily: 'Open Sans', marginBottom: '5px',
    },
    '@media (max-width: 918px)': {
        fontWeight: 400,
        marginBottom: '5px',
         fontSize: '14px', color: '#000000', fontFamily: 'Open Sans', 
    },
    '@media (max-width: 858px)': {
        fontWeight: 400, fontSize: '13px', color: '#000000',
         fontFamily: 'Open Sans',
          marginBottom: '5px',
  
    },'@media (max-width: 768px)': {
        fontWeight: 400, 
        color: '#000000', fontFamily: 'Open Sans', marginBottom: '5px',
        fontSize: '12px',
  
    },'@media (max-width: 562px)': {
        fontWeight: 400, fontSize: '11px',
        marginBottom: '5px',
         color: '#000000', fontFamily: 'Open Sans', 
    },
    '@media (max-width: 626px)': {
        fontWeight: 400, fontSize: '10px',
        fontFamily: 'Open Sans', marginBottom: '5px',
         color: '#000000',
  
    }, '@media (max-width: 468px)': {
        fontWeight: 400, fontSize: '9px', color: '#000000', 
        fontFamily: 'Open Sans', marginBottom: '5px',
  
    },'@media (max-width: 386px)': {
        fontWeight: 400, fontSize: '8px', color: '#000000',
         fontFamily: 'Open Sans', marginBottom: '5px',
    },
   
  })

  const StyledTextLogo=styled.img({
    
    cursor: 'pointer',
    width:'50px',
  
    '@media (max-width: 1018px)': {
        width:'70px',
      cursor: 'pointer',
        
       
    },'@media (max-width: 1034px)': {
        width:'50px',
      cursor: 'pointer',
        
       
    },
    '@media (max-width: 974px)': {
      cursor: 'pointer',

        width:'40px',
        
       
    },'@media (max-width: 918px)': {
      cursor: 'pointer',
      
      width:'40px',
        
       
    },
    '@media (max-width: 858px)': {
      cursor: 'pointer',
      width:'40px',
      
      
      height:'40px'
  
    },
    '@media (max-width: 768px)': {
      cursor: 'pointer',
    
      width:'40px',
  
    },'@media (max-width: 626px)': {
        width:'40px',
      cursor: 'pointer',
  
    }, '@media (max-width: 468px)': {
        width:'30px',
      cursor: 'pointer',
    },'@media (max-width: 386px)': {
      cursor: 'pointer',
      width:'20px',
    },
    
   
  })

  


  const StyleBrainstormInput=styled(Box)({
  
    border: "2px solid #434343", display: 'flex', 
    justifyContent: 'center', alignItems: 'center', 
    overflow: 'auto' , minHeight:"50px",overflowX:'hidden',
    borderRadius: "8px", width: '54%', maxHeight: '60px', 
    overflowY:'hidden',
  
    '@media (max-width: 1018px)': {
        border: "2px solid #434343", display: 'flex', 
        justifyContent: 'center', alignItems: 'center', 
        overflow: 'auto' ,minHeight:"50px",overflowX:'hidden',
        borderRadius: "8px", width: '54%', maxHeight: '60px', 
        overflowY:'hidden',
  
    },'@media (max-width: 1034px)': {
        border: "2px solid #434343", display: 'flex', 
        justifyContent: 'center', alignItems: 'center', 
        overflow: 'auto' ,minHeight:"50px",overflowX:'hidden',
        borderRadius: "8px", width: '54%', maxHeight: '60px', 
        overflowY:'hidden',
  
  
    },
    '@media (max-width: 974px)': {
        border: "2px solid #434343", display: 'flex', 
        justifyContent: 'center', alignItems: 'center', 
        overflow: 'auto' ,minHeight:"50px",overflowX:'hidden',
        borderRadius: "8px", width: '54%', maxHeight: '60px', 
        overflowY:'hidden',
  
    
    },'@media (max-width: 918px)': {
        border: "2px solid #434343", display: 'flex', 
        justifyContent: 'center', alignItems: 'center', 
        borderRadius: "8px", width: '54%', maxHeight: '60px', 
        overflow: 'auto' ,minHeight:"50px",overflowX:'hidden',
        overflowY:'hidden',
  
       
    },
    '@media (max-width: 858px)': {
        border: "2px solid #434343", display: 'flex', 
        borderRadius: "8px", width: '54%', maxHeight: '60px', 
        justifyContent: 'center', alignItems: 'center', 
        overflow: 'auto' ,minHeight:"50px",overflowX:'hidden',
        overflowY:'hidden',
  
    },'@media (max-width: 768px)': {
        justifyContent: 'center', alignItems: 'center', 
        border: "2px solid #434343", display: 'flex', 
        borderRadius: "8px", width: '74%', maxHeight: '60px', 
        overflow: 'auto' ,minHeight:"50px",overflowX:'hidden',
        overflowY:'hidden',
  
  
    },'@media (max-width: 626px)': {
        justifyContent: 'center', alignItems: 'center', 
        border: "2px solid #434343", display: 'flex', 
        borderRadius: "8px", width: '74%', maxHeight: '60px', 
        overflow: 'auto' ,minHeight:"50px",overflowX:'hidden',
        overflowY:'hidden',
  
    },
    '@media (max-width: 468px)': {
   
        justifyContent: 'center', alignItems: 'center', 
        border: "2px solid #434343", display: 'flex', 
    borderRadius: "8px", width: '74%', maxHeight: '60px', 
    overflow: 'auto' , minHeight:"50px",overflowX:'hidden',
    overflowY:'hidden',
    
    },'@media (max-width: 386px)': {
        justifyContent: 'center', alignItems: 'center', 
        border: "2px solid #434343", display: 'flex', 
        borderRadius: "8px", width: '74%', maxHeight: '60px', 
        overflow: 'auto' ,minHeight:"50px",overflowX:'hidden',
        overflowY:'hidden',
  
  
    },
   
  })

  const StyleBrainStormParentBoxInput=styled(Box)({
    width: '100%', display: 'flex', justifyContent: 'center',
    backgroundColor:'#F6F6F6',
   '@media (max-width: 974px)': {
    width: '100%', display: 'flex',
     justifyContent: 'center',
  
    },
    '@media (max-width: 918px)': {
        width: '100%', display: 'flex',
         justifyContent: 'center',
  
    },'@media (max-width: 858px)': {
        width: '100%', display: 'flex', 
        justifyContent: 'center',
  
    },'@media (max-width: 768px)': {
        display: 'flex', justifyContent: 'center',
        alignItems:'center',width: '100%',
  
  
    },
    '@media (max-width: 626px)': {
        display: 'flex', justifyContent: 'center',
        alignItems:'center',width: '100%',
  
    },
    '@media (max-width: 468px)': {
        display: 'flex', justifyContent: 'center',
        alignItems:'center', width: '100%',
  
    
    },'@media (max-width: 386px)': {
        display: 'flex', justifyContent: 'center',
        alignItems:'center',width: '100%',
    },
   
  })

  const StyledBelowInputTypo=styled(Box)({
    width: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center',
    color: '#909090', fontFamily: 'Open Sans',
        '@media (max-width: 1238px)': {
            color: '#909090', fontFamily: 'Open Sans',
            width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center',
        
    
      },
    '@media (max-width: 1034px)': {
        color: '#909090', fontFamily: 'Open Sans',
        width: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center',
    },
    '@media (max-width: 918px)': {
        width: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center',
        color: '#909090', fontFamily: 'Open Sans',
    },
    '@media (max-width: 858px)': {
        color: '#909090', fontFamily: 'Open Sans',
        width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center',
  
    },'@media (max-width: 768px)': {
        width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center',
    color: '#909090', fontFamily: 'Open Sans',
  
    },'@media (max-width: 562px)': {
        width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center',
    color: '#909090', fontFamily: 'Open Sans',
    },
    '@media (max-width: 626px)': {
        color: '#909090', fontFamily: 'Open Sans',
        width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center',
  
    }, '@media (max-width: 468px)': {
        color: '#909090', fontFamily: 'Open Sans',
        width: '80%', display: 'flex', justifyContent: 'center', 
        alignItems: 'center',
  
    },'@media (max-width: 386px)': {
        width: '80%', display: 'flex', justifyContent: 'center',
         alignItems: 'center',
        color: '#909090', fontFamily: 'Open Sans',
    },
   
  })

 
// Customizable Area End