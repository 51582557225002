import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
export interface Tutorial {
  id: string;
  type: string;
  attributes: TutorialAttributes;
}

interface TutorialAttributes {
  created_at: string; 
  updated_at: string; 
  primary_video: string; 
  support_videos: SupportVideo[];
  related_articles: RelatedArticle[];
}

export interface SupportVideo {
  created_at:string;
  title: string;
  description: string | null;
  video_url: string; 
}

interface RelatedArticle {
  created_at: string;
  title: string;
  content: string;
  image_url: string | null;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tutorialData:Tutorial,
  showVideoPlayer: boolean,
  videoModalData: SupportVideo;
  openArticle: RelatedArticle[],
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnboardingguideController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      showVideoPlayer: false,
      videoModalData: {
        created_at: "",
        title: "",
        description: null,
        video_url: ""
      },
      tutorialData:{
        id: "",
        type: "",
        attributes: {
          created_at: "",
          updated_at: "", 
          primary_video: "",
          support_videos: [],
          related_articles: [],
        }
      },
      openArticle: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  // Customizabele Area Start
  getTutorialAPICallId: string = "";

  async componentDidMount() {
    this.getTutorialPageData()
  }

  receiveApi = (
    apiRequestCallId: string,
    responseJson: {
      data: Tutorial;
    }
  ) => {
    if (apiRequestCallId === this.getTutorialAPICallId) {
      this.handleGetItemResponse(
        responseJson.data 
      );
    }
  };

  handleGetItemResponse = (data: Tutorial) => {
    this.setState({
      tutorialData:data,
    },()=>console.log(this.state.tutorialData,"tutorialData123"));
  }
  

  skipOnboarding() {
    const msg: Message = new Message(getName(MessageEnum.OnboardingGuideControllerSkipOnboarding));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  exitOnboarding() {
    const msg: Message = new Message(getName(MessageEnum.OnboardingGuideControllerDoneOnboarding));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  formatDate(isoDateString:string) {
    const date = new Date(isoDateString);

    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });

    return formattedDate;
  }

  getTutorialPageData=()=>{
    this.getTutorialAPICallId = this.apiCall(
      `/bx_block_tutorials/tutorials`,
      "GET",
    );
  }

  showArticle = (item: RelatedArticle) => {
    this.setState({openArticle:[item]})
  }

  apiCall = (endPoint: string, method: string, body?: object,  contentType?: string 
  ) => {
    const header = {
      'Content-Type': 'application/json'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
