import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    accessToken: string;
    error: string;
    accessData: object;
    isSwitch: {
        realSwitch: boolean,
        userSwitch: boolean,
        contentSwitch: boolean
    };
    isDisconnect: boolean;
    isShowBtn: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ConnectedAccountsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    instagramRef: React.RefObject<HTMLDivElement>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            accessToken: "",
            error: "",
            accessData: {},
            isSwitch: {
                realSwitch: true,
                userSwitch: false,
                contentSwitch: true
            },
            isDisconnect: false,
            isShowBtn: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.instagramRef = React.createRef();
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleConnectButtonClick = () => {
        if (this.instagramRef.current) {
            const buttonElement = this.instagramRef.current.querySelector("button");
            if (buttonElement) {
                buttonElement.click();
            }
        }
        this.setState({isShowBtn : false});
    };
    responseInstagram = (response:object) => {
        this.setState({accessData : response})
     };

    handleCloseModal = () =>{
        this.setState({ isDisconnect: false});
      }

    handleOpenModal = () =>{
        this.setState({ isDisconnect: true});
      }
    
    handleDisconnect = () =>{
        this.setState({ isShowBtn: true });
        this.handleCloseModal();
    }
 
    handleAccountSwitch = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ 
            isSwitch: { 
              ...this.state.isSwitch, 
              [event.target.name]: event.target.checked 
            } 
          });
      };
    // Customizable Area End
}