
import React, { ChangeEvent, useState } from "react";
import {
    Box,
    Typography,
    Button,
    MenuItem,
    Menu,
    IconButton,
    Modal,
} from '@mui/material'
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { StyledTextField } from '../../../../components/src/StyledComponents'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { facebookIcon, inboxIcon, instagramIcon, noteICon,renameIcon } from "../assets";
interface Props {
    onRename: (id:string, newName: string) => void
    onDelete: (id: string) => void
    onClick: () => void
    campaign: {
        id: string,
        type: string,
        attributes: {
            id: number
            name: string,
            created_at: string,
            updated_at: string
        },
        relationships: {
            account: {
                data: {
                    id: string,
                    type: string
                }
            }
        }
    }
}
const CampaignCard: React.FC<Props> = ({ campaign, onRename, onDelete, onClick }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openRenameModal, setOpenRenameModal] = useState<boolean>(false);
    const [nameError, setNameError] = useState<boolean>(false);
    const [nameErrorMessage, setNameErrorMessage] = useState<string>("");
    const [name, setName] = useState<string>("");

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true)
        handleClose()
    }
    const handleCloseDeleteModal = () => setOpenDeleteModal(false)
    const handleOpenRenameModal = () => {
        setOpenRenameModal(true)
        handleClose()
    }
    const handleCloseRenameModal = () => {
        setOpenRenameModal(false);
        setName("");
        setNameError(false)
        setNameErrorMessage("")
    }
    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }
    const handleDelete = () => {
        handleCloseDeleteModal()
        onDelete(campaign.id)
    }
    const handleRename = () => {
        if(name.length>0){            
            onRename(campaign.id, name)
            handleCloseRenameModal()
        }else{
            setNameError(true)
            setNameErrorMessage("Campaign name required")
        }       
    }
    return (
        <>
            <StyledCampaignCard onClick={onClick}>
                <Box sx={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", gap: "12px", justifyContent: "space-between" }}>
                    <Box sx={{ display: 'flex', width: "100%", justifyContent: "space-between", alignItems: "center", position: "relative" }}>
                        <Typography sx={{ fontSize: "12px", fontFamily: "Open Sans", fontWeight: 400, borderRadius: "100px", background: "#FFFBAA", color: "#4D4D4D", padding: "2px 8px" }}>
                            {`Created on: ${new Date(campaign.attributes.created_at).toLocaleString('en-US', { month: 'short', year: 'numeric' })} `}
                        </Typography>
                        <IconButton
                            data-testid='openMenuButton'
                            aria-controls={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={handleOpenMenu}
                            sx={{ padding: "0px" }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <StyledMenu
                            id="simple-menu"
                            data-testid='cardMenu'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            sx={{ mt: -1, ml: 1 }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem onClick={handleOpenDeleteModal} data-testid='deleteOption' sx={{display:"flex", gap:"10px", alignItems:"center", padding:'6px , 10px', fontWeight:400, fontFamily:'Open Sans'}}><DeleteOutlineOutlinedIcon/>Delete</MenuItem>
                            <div style={{display:"flex", height:"1px", background:"#DDDDDD"}}/>
                            <MenuItem onClick={handleOpenRenameModal} data-testid='renameOption' sx={{display:"flex", gap:"10px", alignItems:"center", padding:'6px , 10px', fontWeight:400, fontFamily:'Open Sans'}}> <img src={renameIcon}/>Rename</MenuItem>
                        </StyledMenu>
                    </Box>
                    <Box sx={{ flexGrow: 1, overflowY: "auto", fontSize: "24px", fontFamily: "Open Sans", fontWeight: 600, color: "#000000", padding: "2px" }}>
                        {campaign.attributes.name}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", maxWidth: "220px", padding: "8px 16px 8px 16px", background: "#FFFBAA", borderRadius: "100px" }}>
                        <Box sx={{ display: "flex", width: "fit-content", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                            <img src={facebookIcon} />
                            <span style={{ fontSize: "12", fontFamily: "Open Sans", fontWeight: 400, color: "#4D4D4D" }}>{'10'}</span>
                        </Box>
                        <Box sx={{ display: "flex", width: "fit-content", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                            <img src={inboxIcon} />
                            <span style={{ fontSize: "12", fontFamily: "Open Sans", fontWeight: 400, color: "#4D4D4D" }}>{'10'}</span>
                        </Box>
                        <Box sx={{ display: "flex", width: "fit-content", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                            <img src={instagramIcon} />
                            <span style={{ fontSize: "12", fontFamily: "Open Sans", fontWeight: 400, color: "#4D4D4D" }}>{'10'}</span>
                        </Box>
                        <Box sx={{ display: "flex", width: "fit-content", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                            <img src={noteICon} />
                            <span style={{ fontSize: "12", fontFamily: "Open Sans", fontWeight: 400, color: "#4D4D4D" }}>{'10'}</span>
                        </Box>
                    </Box>
                </Box>
            </StyledCampaignCard>
            <Modal
                data-testid='deleteModal'
                open={openDeleteModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: "transparent" }}
                onClose={handleCloseDeleteModal}
                BackdropProps={{
                    sx: { background: '#00000080' }
                }}
            >
                <StyledModalContainer>
                    <StyledCloseIcon onClick={handleCloseDeleteModal} />
                    <Box sx={{ display: 'flex', gap: "12px", flexDirection: "column", alignItems: "center" }} >
                        <Typography sx={{ fontFamily: "Open Sans", fontSize: "20px", fontWeight: 600, color: "#4D4D4D", textAlign: "center" }}>Delete Campaign</Typography>
                        <Typography sx={{ fontFamily: "Open Sans", fontSize: "16", fontWeight: 400, color: "#4D4D4D", textAlign: "center" }}>Are you sure do you want to delete Campaign Name?</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "12px" }}>
                        <Button
                            // variant="outlined"
                            fullWidth
                            sx={{
                                textTransform: "none",
                                fontFamily: "Open Sans",
                                fontSize: '16px',
                                fontWeight: 600,
                                border: "1px solid #000000",
                                borderRadius: "8px",
                                color: "#000000",
                                maxWidth: "212px",
                                "&:hover": {
                                    background: '#eaeaeacf;',
                                    borderColor: "1px solid #000000",
                                }
                            }}
                            onClick={handleCloseDeleteModal}
                        >
                            No, cancel
                        </Button>
                        <Button
                            fullWidth
                            data-testid='deleteButton'
                            sx={{
                                textTransform: "none",
                                fontFamily: "Open Sans",
                                fontSize: '16px',
                                fontWeight: 600,
                                border: "1px solid #000000",
                                borderRadius: "8px",
                                color: "#ffffff",
                                background: "#000000",
                                padding: '10px',
                                maxWidth: "212px",
                                "&:hover": {
                                    background: "#000000",
                                }

                            }}
                            onClick={handleDelete}
                        >
                            Yes, delete

                        </Button>
                    </Box>
                </StyledModalContainer>
            </Modal>
            <Modal
                data-testid={'renameModal'}
                open={openRenameModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: "transparent" }}
                onClose={handleCloseRenameModal}
                BackdropProps={{
                    sx: { background: '#00000080' }
                }}
            >
                <StyledModalContainer>
                    <StyledCloseIcon onClick={handleCloseRenameModal} />
                    <Box sx={{ display: 'flex', gap: "12px", flexDirection: "column", alignItems: "center" }} >
                        <Typography sx={{ fontFamily: "Open Sans", fontSize: "20px", fontWeight: 600, color: "#4D4D4D", textAlign: "center" }}>Rename</Typography>
                        <StyledTextField
                            data-testid='nameInput'
                            fullWidth
                            height="35px"
                            onChange={handleChangeName}
                            value={name}
                            error={nameError}
                            helperText={nameErrorMessage}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "12px" }}>
                        <Button
                            // variant="outlined"
                            fullWidth
                            sx={{
                                textTransform: "none",
                                fontFamily: "Open Sans",
                                fontSize: '16px',
                                fontWeight: 600,
                                border: "1px solid #000000",
                                borderRadius: "9px",
                                color: "#000000",
                                maxWidth: "212px",
                                "&:hover": {
                                    // background: '#eaeaeacf',
                                    borderColor: "#000000"
                                }
                            }}
                            onClick={handleCloseRenameModal}
                        >
                            No, cancel
                        </Button>
                        <Button
                            data-testid='saveButton'
                            fullWidth
                            sx={{
                                textTransform: "none",
                                fontFamily: "Open Sans",
                                fontSize: '16px',
                                fontWeight: 600,
                                border: "1px solid #000000",
                                borderRadius: "8px",
                                color: "#ffffff",
                                background: "#000000",
                                padding: '9px',
                                maxWidth: "212px",
                                "&:hover": {
                                    background: "#000000",
                                }

                            }}
                            onClick={handleRename}
                        >
                            Save

                        </Button>
                    </Box>
                </StyledModalContainer>
            </Modal>
        </>
    )
}

export default CampaignCard;

const StyledCampaignCard = styled(Box)({
    width: "260px",
    height: '218px',
    border: "1px solid #DDDDDD",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    background: "#FFFDD8",
    borderRadius: "8px",
    padding: "24px 16px 24px 16px",
    gap: "36px",
    '&:hover': {
        background: "#FCF670",
        cursor: "pointer"
    },
    '@media (max-width: 363px)': {
        width: 'clamp(150px, 70vw, 260px)',
    }
})

const StyledMenu = styled(Menu)({
    '& .MuiPaper-root': {
        backgroundColor: '#FFFFFF',
        borderRadius: "10px",
        border: "1px solid #DDDDDD",
        height: "fit-content"
    },
    '& .MuiList-root': {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    '& .MuiMenuItem-root': {
        '&:hover': {
            backgroundColor: '#ccc',
        },
    },
})

const StyledCloseIcon = styled(CloseOutlinedIcon)({
    position: "absolute",
    color: "#878787",
    fontSize: "24px",
    cursor: "pointer",
    zIndex: 5,
    top: 15,
    right: 15,
})

const StyledModalContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    background: "#ffffff",
    width: '529px',
    height: "fit-content",
    gap: "40px",
    borderRadius: "24px",
    position: "relative",
    padding: "40px",
    margin: "12px"
})

