Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BreadcrumbNavigation";
exports.labelBodyText = "BreadcrumbNavigation Body";
exports.InputPlaceHolder = "Create Pages";
exports.BTNSubmit = "Submit";
exports.btnExampleTitle = "CLICK ME";
exports.profile = "My Profile";
exports.business = "My Business";
exports.home = "Home";
exports.settings = "Settings";
exports.latestUpdates = "Latest Updates";
exports.helpCenter = "Help Center";
exports.logout = "Log Out";
exports.subscription = "Subscription plan";
exports.getMore = "Get more benefits with";
exports.content = "Content Creation";
exports.myworkSpace = "My workspace";
exports.security = "Security";
exports.notifications = "Notifications";
exports.membership = "Membership";
exports.connectedAccounts = "Connected Accounts";
exports.faqHeading = "FAQ";
exports.tutorials = "Tutorials";
exports.contactUs = "Contact Us";
exports.generateContent = "Generate Content";


exports.token = "";
// Customizable Area End
