// Customizable Area Start
import React from "react";

import { Box, Typography } from "@mui/material";
import Switch from '@mui/material/Switch';
import DashboardCommunityManagerController, {
    Props,
    
} from "./DashboardCommunityManagerController.web";
import { Dialog, DialogContent, IconButton, Link, styled } from "@material-ui/core";
import { deleteIcon, deletedTickIcon, editIcon, groupVideo, plusIcon } from "./assets";
import { closeIcon } from "../../chatgpt/src/assets";

export default class DashboardCommunityManager extends DashboardCommunityManagerController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box style={webStyles.mainContainer}>
                <Box style={webStyles.topContainer}>
                    <Box style={webStyles.topContTop}>
                        <Box style={webStyles.topContTopLeft}>
                            <Typography style={webStyles.topContainerHeading}>Create your own Community Manager</Typography>
                            <Typography style={webStyles.topContainerSubHeading}>Our AI-powered community manager allows you to build and customize a virtual assistant to enhance your engagement and support. Tailor the bot to fit your needs, automate interactions, personalize user experiences, and gather valuable insights.</Typography>
                        </Box>
                        <Box style={webStyles.topContTopRight}>
                            <img style={webStyles.topContRightImg} src={groupVideo} alt="" />
                        </Box>
                    </Box>
                    <Box style={webStyles.benefitsMainBox}>
                        {
                            this.state.topHeadings.map((heading) => {
                                return (
                                    <Box key={heading.title} style={webStyles.benefitsBox}>
                                        <Box style={webStyles.benefitsBox2}>
                                            <img style={webStyles.benefitsIcon} src={heading.icon} alt="" />
                                            <Typography style={webStyles.benefitsTitle}>{heading.title}</Typography>
                                        </Box>
                                        <Typography style={webStyles.benefitsDesc}>{heading.desc}</Typography>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>

                <Box style={webStyles.bottomContainer}>
                    <Box data-test-id='redirectToCreate' style={webStyles.bottomCreateBoxes} onClick={this.redirectToCreate}>
                        <Typography style={webStyles.createText}>Create a Community Manger</Typography>
                        <img src={plusIcon} alt="" />
                    </Box>
                    {
                        this.state.current_CM_list && this.state.current_CM_list.map((myCm) => {
                            return (
                                <Box key={myCm.id}>
                                    <Box style={webStyles.bottomBoxes}>
                                        <Typography style={webStyles.bottomBoxTitle}>{myCm.attributes.name}</Typography>
                                        <Box style={webStyles.bottomBoxSocialBox}>
                                            {
                                                myCm.attributes.brand_guideline.length > 0 && myCm.attributes.brand_guideline.map((icon) => {
                                                    return <img key={icon.filename} style={webStyles.bottomBoxSocialIcon} src={icon.filename} alt="" />
                                                })
                                            }
                                        </Box>
                                        <Typography style={webStyles.bottomBoxDivider}></Typography>
                                        <Box style={webStyles.bottomActionBox}>
                                            <Typography style={webStyles.activeTxt}>Active</Typography>
                                            <Switch data-test-id='handleSwitchChange' onChange={(event) => this.handleSwitchChange(myCm.id)} checked={myCm.attributes.status === 'active' ? true : false} />
                                            <Link href={`EditCommunityManager/${myCm.id}`}>
                                                <img src={editIcon} alt="" />
                                            </Link>
                                            <img src={deleteIcon} alt=""  data-test-id='btnOpenDelteModal' onClick={() => this.handleOpenDeleteModal(myCm.attributes.name, myCm.id)}/>
                                        </Box>
                                        <Box style={webStyles.bottomBoxDateBox}>
                                            <Typography style={webStyles.bottomBoxDate}>Last interaction</Typography>
                                            <Typography style={webStyles.bottomBoxDate}>{this.formatDate(myCm.attributes.updated_at)}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })
                    }

                </Box>

                <StyledDialog
                    onClose={this.handleCloseDeleteModal}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.isModalOpen}
                >
                    {
                        !this.state.isDeleteClicked && <IconBtn
                            aria-label="close"
                            onClick={this.handleCloseDeleteModal}
                        >
                            <img src={closeIcon} alt="close_icon" />
                        </IconBtn>
                    }

                    <DialogContent dividers>
                        {
                            this.state.isDeleteClicked
                                ? <Box>
                                    <Typography style={webStyles.deleteTitle}>“{this.state.currentCmName}” Community Manger was deleted</Typography>
                                    <img style={webStyles.deletedTickIconStyle} src={deletedTickIcon} alt="" />
                                    <button style={webStyles.closeBtn} onClick={this.handleCloseDeleteModal}>Close</button>
                                </Box>
                                : <>
                                    <Typography style={webStyles.deleteTitle}>Delete “{this.state.currentCmName}” Community Manger</Typography>
                                    <Typography style={webStyles.deleteWarningText}>You will not be able to restore it</Typography>
                                    <Box style={webStyles.deleteButtonBox}>
                                        <button data-test-id='btnCloseDelteModal' onClick={this.handleCloseDeleteModal} style={webStyles.cancelBtn}>Cancel</button>
                                        <button data-test-id='handleDelete' onClick={this.deleteCommunity} style={webStyles.deleteBtn}>Delete</button>
                                    </Box>
                                </>
                        }

                    </DialogContent>
                </StyledDialog>
            </Box>
        );

    }
}

const webStyles = {
    mainContainer: {
        margin: "32px 24px 42px 24px",
        paddingBottom: '90px',
    },
    topContainer: {
        border: '1px solid #059669',
        borderRadius: "16px",
        padding: "24px 24px 24px 24px",
    },
    topContTop: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    topContTopLeft: {
        width: '100%',
    },
    topContTopRight: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
    },
    topContainerHeading: {
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontSeight: 700,
        color: '#059669',
    },
    topContainerSubHeading: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#4D4D4D',
        marginTop: '16px',
    },
    topContRightImg: {
        width: "80%",
    },
    benefitsMainBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '48px',
        columnGap: '16px',
    },
    benefitsBox: {},
    benefitsBox2: {
        display: 'flex',
        alignItems: 'center',
    },
    benefitsIcon: {},
    benefitsTitle: {
        fontFamily: ' Open Sans',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '27.24px',
        color: '#059669',
    },
    benefitsDesc: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        color: '#4D4D4D',
        marginTop: '16px',
    },
    bottomContainer: {
        marginTop: '27px',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '16px',
        boxSizing: 'border-box' as 'border-box',
    },
    bottomCreateBoxes: {
        boxSizing: 'border-box' as 'border-box',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '60px 0 60px 0',
        cursor: 'pointer',
        border: '1px dashed #059669',
        borderRadius: '12px',
    },
    createText: {
        width: "204px",
        fontFamily: 'Open Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#059669',
        textAlign: "center" as "center",
        marginBottom: '16px',
    },
    bottomBoxes: {
        padding: "24px 24px 24px 24px",
    },
    bottomBoxTitle: {
        fontFamily: 'Open Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#4D4D4D',
    },
    bottomBoxSocialBox: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '4px',
        marginTop: '16px',
    },
    bottomBoxSocialIcon: {},
    bottomBoxDivider: {
        border: '1px solid #EFEFEF',
        margin: '16px 0 16px 0',
    },
    bottomActionBox: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '16px',
    },
    activeTxt: {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 600,
        color: '#059669'
    },
    bottomBoxDateBox: {
        marignTop: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomBoxDate: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#B3B3B3',
    },
    deleteTitle: {
        fontFamily: 'Open Sans',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '27.24px',
        color: '#059669',
        textAlign: 'center' as 'center',
    },
    deleteWarningText: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '21.79px',
        color: '#4D4D4D',
        marginTop: '16px',
        textAlign: 'center' as 'center',
    },
    deleteButtonBox: {
        marginTop: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cancelBtn: {
        width: '187.5px',
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        background: '#059669',
        border: 'none',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    deleteBtn: {
        width: '187.5px',
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        background: 'none',
        border: '1px solid #059669',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#059669',
        cursor: 'pointer',
    },
    deletedTickIconStyle: {
        display: 'block',
        margin: '40px auto 40px auto',
    },
    closeBtn: {
        width: '100%',
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        background: '#059669',
        color: '#fff',
    },
}

export const IconBtn = styled(IconButton)({
    position: 'absolute',
    top: 8,
    right: 8,
});

export const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        width: '479px',
        borderRadius: "24px",
        boxSizing: 'border-box',
    },
    '& .MuiDialogContent-root': {
        padding: '40px 40px 40px 40px',
    },
});
// Customizable Area End
