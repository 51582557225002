import React from 'react';
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Dialog,
  DialogContent,
  IconButton
} from '@mui/material';
import { instaIcon, closeIcon } from './assets';
import ConnectedAccountsController, { Props, configJSON } from './ConnectedAccountsController.web';
import BreadcrumbNavigationPage from '../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web';
import { InstagramLogin } from '@amraneze/react-instagram-login';
// Customizable Area End

export default class ConnectedAccounts extends ConnectedAccountsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInstaAccount = () => {
    const redirectUrl = window.location.href;
    return (
      <AccountBox>
        <InstaBox>
          <img src={instaIcon} className="insta_img" />
          <Typography className='insta_text'>{configJSON.instagram}</Typography>
        </InstaBox>
        {this.state.isShowBtn ? <ConnectButton onClick={this.handleConnectButtonClick} data-testId="connectaccountTestID">{configJSON.connect}</ConnectButton>
        : <DisConnectButton onClick={this.handleOpenModal} data-testId="disconnectModalTestID">{configJSON.disconnect}</DisConnectButton>}
        <InstaConnectBox ref={this.instagramRef}>
          <InstagramLogin
            clientId="1273774676970861"
            buttonText="Login"
            onSuccess={this.responseInstagram}
            onFailure={this.responseInstagram}
            redirectUri={redirectUrl}
            useRedirect={true}
            scope={"openid profile email"}
          />
        </InstaConnectBox>
        {this.renderDisconnectModal()}
      </AccountBox>
    )
  }

  renderDisconnectModal = () => {
    return (
      <StyledDialog
        onClose={this.handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.isDisconnect}
      >
        <IconBtn
          aria-label="close"
          data-test-id='btnCloseModal'
          onClick={this.handleCloseModal}
        >
          <img src={closeIcon} alt="close_icon" />
        </IconBtn>
        <DialogContent dividers>
          <ModalHeading>{configJSON.disConnectHeading}</ModalHeading>
          <ModalContent>{configJSON.disConnectContent}</ModalContent>
          <InstaBoxModal>
            <img src={instaIcon} className="insta_img" />
            <Typography className='insta_text_modal'>{configJSON.instagram}</Typography>
          </InstaBoxModal>
          <ButtonBox>
            <CancelButton onClick={this.handleCloseModal}>{configJSON.cancel}</CancelButton>
            <DisConnectModalBtn onClick={this.handleDisconnect} data-testId="disconnectaccountTestID">{configJSON.disconnect}</DisConnectModalBtn>
          </ButtonBox>
        </DialogContent>
      </StyledDialog>
    )
  }

  renderConnectedAccountsPage = () => {
    const {realSwitch, userSwitch, contentSwitch} = this.state.isSwitch;
    const {active , paused} = configJSON;
    return (
      <MainContainer>
        <ConnectHeading>{configJSON.ConnectAccount}</ConnectHeading>
      <TableContainers>
      <AccountTable>
        <TableHead>
          <TableRow>
            <TableCellTH align="left">{configJSON.accountTH}</TableCellTH>
            <TableCellTH align="center">{configJSON.realTH}</TableCellTH>
            <TableCellTH align="center">{configJSON.userTH}</TableCellTH>
            <TableCellTH align="center">{configJSON.contentTH}</TableCellTH>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow >
              <TableCell> {this.renderInstaAccount()} </TableCell>
              <TableCellTB align="center"><SwitchText>{realSwitch ? active : paused}</SwitchText><Switch className='switch_account' checked={realSwitch} data-testId="realTimeTestID" onChange={this.handleAccountSwitch} name="realSwitch"/></TableCellTB>
              <TableCellTB align="center"><SwitchText>{userSwitch ? active : paused}</SwitchText><Switch className='switch_account' checked={userSwitch} onChange={this.handleAccountSwitch} name="userSwitch"/></TableCellTB>
              <TableCellTB align="center"><SwitchText>{contentSwitch ? active : paused}</SwitchText><Switch className='switch_account' checked={contentSwitch} onChange={this.handleAccountSwitch} name="contentSwitch"/></TableCellTB>
            </TableRow>
        </TableBody>
      </AccountTable>
    </TableContainers>
    </MainContainer>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BreadcrumbNavigationPage navigation={this.props.navigation}>
        {this.renderConnectedAccountsPage()}
      </BreadcrumbNavigationPage>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  padding: "50px 30px"
})

const ConnectHeading = styled(Box)({
  marginTop: "24px",
  fontFamily: 'Open Sans',
  fontSize: '24px',
  fontWeight: 600,
  color: '#000000'
})

const AccountBox = styled(Box)({
  width: "250px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  borderRadius: "12px",
  padding: "8px 16px",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  "& .insta_img": {
    height: "20px"
  },
  "& .insta_text": {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7153B8'
  }
})

const InstaBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "12px"
})

const InstaBoxModal = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "12px",
  marginTop: "16px",
  "& .insta_text_modal": {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7153B8'
  }
})

const ConnectButton = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: '#000000',
  color: "#FFFFFF",
  padding: "8px 16px",
  borderRadius: "8px",
  cursor: "pointer"
})

const DisConnectButton = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 700,
  color: "#000000",
  padding: "8px",
  borderRadius: "8px",
  cursor: "pointer",
  border: "1px solid #A8A8A8"
})

const InstaConnectBox = styled(Box)({
   display: "none",
   height: "100vh"
})

const TableContainers = styled(TableContainer)({
   marginTop: "30px",
   width: "100%",
   height: "100vh",
   padding:"0px 5px"
})

const AccountTable = styled(Table)({
  "& .MuiTableBody-root":{
    borderRadius: "16px !important",
    border: "1px solid #DFDFDF",
  },
  "& .MuiTableCell-head":{
    borderBottom : "none",
  },
  "& .MuiTableCell-body	":{
    borderBottom : "none",
    borderLeft: "1px solid #DFDFDF",
  },
})

const TableCellTH = styled(TableCell)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 600,
  color: '#0E0E0E'
})

const SwitchText = styled('span')({
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: 600,
  color: '#4D4D4D'
})

const TableCellTB = styled(TableCell)({
   "& .switch_account":{
    gap:"20px",
    "&.css-julti5-MuiSwitch-root":{
      height:"28px !important",
      width:"48px !important",
      borderRadius: "20px !important",
      padding: "0px",
      backgroundColor: "#E2E8F0",
      opacity:1,
      alignItem: "center",
      justifyContent: "center",
      margin: "0px 5px"
    },
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase":{
      padding: "0px",
      width: "28px",
      height: "28px"
    },
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":{
       color: "#FFFFFF"
    },
    "& .css-jsexje-MuiSwitch-thumb":{
      width: "24px",
      height: "24px"
    },
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":{
      backgroundColor: "#000000",
      opacity: 1
    }
   }
})

const StyledDialog = styled(Dialog) ({
   textAlign: "center",
  "& .MuiDialog-paper": {
    width: '529px',
    borderRadius: "24px",
    boxSizing: 'border-box',
  },
  '& .MuiDialogContent-root': {
      padding: '40px 40px',
  },
});

const IconBtn = styled(IconButton)({
  position: 'absolute',
  right: 16,
  top: 16,
});

const ModalHeading = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '20px',
  fontWeight: 600,
  color: "#4D4D4D",
})

const ModalContent = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 400,
  color: "#4D4D4D",
  marginTop:"16px"
})

const ButtonBox = styled(Box)({
  display: "flex",
  marginTop: "35px",
  width: "100%",
  justifyContent: "space-evenly",
  gap: "20px"
})

const CancelButton = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 600,
  backgroundColor: '#000000',
  color: "#FFFFFF",
  padding: "10px 16px",
  borderRadius: "8px",
  cursor: "pointer",
  flex: "fit-content"
})

const DisConnectModalBtn = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 700,
  color: "#000000",
  padding: "10px",
  borderRadius: "8px",
  cursor: "pointer",
  border: "1px solid #A8A8A8",
  flex: "fit-content"
})

// Customizable Area End 