import React from "react";

// Customizable Area Start
import { StyleSheet } from "react-native";
import {
  Typography,
  Box,
  FormControl,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { googleIcon, loginImage } from "./assets";

const StyledContainer = styled(Box)({
  display:"flex",
  flexDirection:"column",
  background:"#FFFFFF",
  width:"fit-content",
  borderRadius:"24px",
  padding:"30px",
  overflowY:"auto",
  '@media (min-height: 616px)': {
    maxHeight:'fit-content',
  },
  '@media (max-height: 615px)': {
    maxHeight:'82vh',
  },
  '@media (max-height: 530px)': {
    maxHeight:'80vh',
  },
  '@media (max-height: 430px)': {
    maxHeight:'76vh',
  },
  '@media (max-height: 350px)': {
    maxHeight:'71vh',
  },
  '@media (max-height: 290px)': {
    maxHeight:'65vh',
  },
  '@media (max-height: 250px)': {
    maxHeight:'50vh',
  },
  '@media (max-height: 200px)': {
    maxHeight:'40vh',
  },
  '@media (max-height: 100px)': {
    maxHeight:'25vh',
  },
  "::-webkit-scrollbar": {
      display: 'none',
    },
    "-ms-overflow-style": 'none',
    "scrollbar-width": 'none'
    
});

const StyledDividerLine = styled(Box)({
  display:"flex",
  height:'1px',
  flex:1,
  background:"#000000",
})
const imageStyle={
  objectFit: 'cover' as 'cover',
  objectPosition: "center",
  overflow: "hidden",
}
const StyledInnerContainer=styled(Box)({
  display:"flex",
  flexDirection :'column',
  alignItems:"center",
  gap:"16px",
  maxWidth:"300px",
  width:"100%",
  height:"fit-content",
})
const StyledFailedErrorContainer=styled(Box)({
  display:"flex",
  flexDirection :'column',
  alignItems:"center",
  width:"370px",
  height:"fit-content",
  gap:"13vh",
  '@media (max-width: 480px)': {
    width:'100%',
  },
  '@media (max-height: 360px)': {
    gap:"7vh",
  },
  '@media (max-height: 260px)': {
    gap:"10px",
  },
})
const buttonText={
  fontFamily:"Open Sans",
  fontSize:"14px",
  fontWeight:400,
  color:"#000000"
}

const StyledTextField = styled(TextField) ({
  '& .MuiOutlinedInput-root': {
    height: "48px",
    borderRadius: "8px",
    '& fieldset': {
      borderColor: '#6F6F6F',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F6F6F',
    },
    '&:hover fieldset': {
      borderColor: '#6F6F6F',
    },
  },
  '&[aria-invalid="true"] .MuiOutlinedInput-root':{
      '&.Mui-focused fieldset': {
          borderColor: '#E04545',
        },
        '&:hover fieldset': {
          borderColor: '#E04545',
        },
        '& fieldset': {
          borderColor:'#E04545',
        },
  },
  '& .MuiInputBase-input': {
    height: '100%', 
  },
});

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  flexDirection: "column",
  width:"100%",
  gap: "4px",
  '& .MuiFormHelperText-root': {
    marginLeft:"0px",
    fontWight:600,
  },
});

const StyledLabel = styled(Typography)({
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight:"16px",
  color:"#000000",
  '&[data-error="true"]':{
      color: '#E04545',
  },
});
const StyledCloseIcon = styled(CloseOutlinedIcon)({
  position: "absolute",
  color: "#878787",
  fontSize: "24px",
  cursor: "pointer",
  zIndex:5,
  top:13,
  right:13,
});
const webStyles = {
  outer: {
    background: "#FFFFFF",
    maxHeight: 'fit-content',
    width: "fit-content",
    borderRadius: "24px",
    margin: "10px",
    position: "relative",
    border: '10px solid #FFFFFF'
  },
  dividerContainer: {
    display: 'flex',
    gap: "14px",
    height: "16px",
    justifyContent: "center",
    alignItems: "center",
    width: '100%'
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
    minHeight: "50px",
    height:"fit-content"
  },
  boldHeader: {
    fontFamily: "Open Sans",
    fontSize: "22px",
    fontWeight: 700,
    color: "#000000",
    lineHeight: '30px'
  },
  subHeader: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 600,
    color: "#000000",
    lineHeight: '16px'
  },
  forgotPasswordContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "right"
  },
  forgetPasswordButton: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: '19px',
    color: "#000000",
    cursor:"pointer",
  },
  loginButton: {
    color: "#ffff",
    background: "#202020",
    borderRadius: "8px",
    textTransform: "none",
    height: "42px",
    fontSize: "16px",
    fontWeight: "600",
    cursor:"pointer",
    '&:hover':{
      background: "#202020",
    }
  },
  googleLogInButton: {
    display: 'flex',
    gap: "8px",
    padding: "2.5px",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #000000",
    borderRadius: "8px",
    minHeight: '35px',
    height:'fit-content',
    width: `calc(100% - 7px)`,
    cursor:"pointer",
  },
  registrationMessage: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    textAlign: "center",
    lineHeight: "19px"
  },
  loginFailedHeader: {
    fontFamily: "Open Sans",
    fontSize: "22px",
    fontWeight: 700,
    color: "#000000",
    textAlign: "center",
  },
  loginFailed: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 600,
    color: "#4D4D4D",
    textAlign: "center",
  }

}
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box 
        sx={webStyles.outer} 
        data-testid='logInFormOuterContainer' 
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => { event.stopPropagation(); }}
      >
        <StyledContainer data-test-id='loginFormContainer'>
          {!this.state.isLoginFailed ? <StyledInnerContainer data-testid='loginForm'>
            <Box sx={webStyles.imageContainer}>
            <img src={loginImage} style={imageStyle} />
            </Box>
            <Box sx={webStyles.headerContainer}>
              <Typography style={webStyles.boldHeader}>
                Welcome to DatAI!
              </Typography>
              <Typography style={webStyles.subHeader}>
               Log In
              </Typography>
            </Box>
              <StyledFormControl error={this.state.emailError}>
                <StyledLabel data-error={this.state.emailError}>Email Address</StyledLabel>
                <StyledTextField
                  data-testid='emailInput'
                  aria-invalid={this.state.emailError}
                  placeholder="Email Address"
                  onChange={this.handleChangeEmail.bind(this)}
                  value={this.state.email}
                  error={this.state.emailError}
                  helperText={this.state.emailErrorMessage}
                />
              </StyledFormControl>
              <StyledFormControl error={this.state.passwordError}>
                <StyledLabel data-error={this.state.passwordError}>Password</StyledLabel>
                <StyledTextField
                  data-testid='passwordInput'
                  type={this.state.showPassword ? 'text' : "password"}
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChangePassword.bind(this)}
                  error={this.state.passwordError}
                  aria-invalid={this.state.passwordError}
                  helperText={this.state.passwordErrorMessage}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment 
                        position="end" 
                        data-testid='showConfirmPasswordContainer' 
                        data-show={this.state.showPassword}
                      >
                        {this.state.showPassword ?
                          <VisibilityOutlinedIcon 
                            data-testid="eyeOpen" 
                            sx={{cursor:"pointer"}}
                            onClick={() => { this.setState({ showPassword: false }); }} 
                          /> :
                          <VisibilityOffOutlinedIcon 
                            data-testid="eyeClose" 
                            sx={{cursor:"pointer"}}
                            onClick={() => { this.setState({ showPassword: true }); }} 
                          />}
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledFormControl>              
              <Box sx={webStyles.forgotPasswordContainer}>
                <span style={webStyles.forgetPasswordButton} data-testid={'forgetPasswordButton'} onClick={this.props.goToForgetPassword}>
                  Forgot Password?
                </span>
              </Box>
              <Button
                data-testid="loginButton"
                fullWidth
                sx={webStyles.loginButton}
                onClick={this.handleLogIn.bind(this)}
              >
                Log In
              </Button>
              <Box style={webStyles.dividerContainer}>
                <StyledDividerLine />
                <Box style={buttonText}>Or Log In with</Box>
                <StyledDividerLine />
              </Box>
              <Box style={webStyles.googleLogInButton}>
                <img src={googleIcon} />
                <span style={buttonText}>Log In with Google</span>
              </Box>
            <Typography sx={webStyles.registrationMessage}>
              Do not have an account?<span style={{ fontWeight: 700,cursor:"pointer", }} onClick={this.props.goToRegistration}> Register</span>
            </Typography>
          </StyledInnerContainer>: 
          <StyledFailedErrorContainer>
            <Box sx={webStyles.imageContainer}>
              <img src={loginImage} style={imageStyle} />
            </Box>
            <Box sx={{display:'flex', justifyContent:"center", alignItems:"center", flexDirection:"column", gap:"16px"}}>
              <Typography data-testid="loginFailedHeader" sx={webStyles.loginFailedHeader}>Ooops...</Typography>
              <Typography data-testid="loginFailedMessage" sx={webStyles.loginFailed}>This email or password is not recognised. Please try again.</Typography>
            </Box>
            <Button
                data-testid="backTologinButton"
                fullWidth
                sx={webStyles.loginButton}
                onClick={()=>{this.setState({isLoginFailed:false})}}
                >
                Back to Log In
            </Button>
          </StyledFailedErrorContainer> }
        </StyledContainer>
        <StyledCloseIcon data-testid={"closeButton"} onClick={this.props.onClose} />
      </Box>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
