// Customizable Area Start
import React from 'react';

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  styled,
  TextField,
  Dialog,
  DialogContent,
  IconButton
  // Customizable Area End
} from '@mui/material';

// Customizable Area Start
interface MessageArray {
  id: number;
  message: string;
  isUser: boolean;
}
import BreadcrumbNavigationPage from '../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web';
import { searchIcon, starIcon, productIcon, campaignIcon, subcriberIcon, blogIcon, btnbackIcon, closeIcon, dislikeIcon, editIcon, reloadIcon, sendIcon, speakerIcon } from './assets';
import DottedCircularLoader from '../../../components/src/DottedCircularLoader';
// Customizable Area End

import ChatgptController, { Props, configJSON } from './ChatgptController';

export default class Chatgpt extends ChatgptController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
    renderGenerateContent = () => {
      return(<>
        <MainContainer>
         <GenerateBox>
           <HeadingContent data-testId="generateLabelTestID">{configJSON.letsCreate}</HeadingContent>
           <InputFieldBox>
           <img src={searchIcon} className="search_img" />
           <TextField className='textField'/>
           <Box onClick={this.handleChatPage} data-testId="generateContentTestID" className='textFieldBtn'>{configJSON.generate}<img src={starIcon} className="star_img" /></Box>
           </InputFieldBox>
           <ExampleContent>{configJSON.exampleContent}</ExampleContent>
           <ContentHeading>{configJSON.usefulContent}</ContentHeading>
            <StartPointContainer>
              <StartPointBox>
                <img src={productIcon} className="start_img" />
                <Typography className="start_point_content">{configJSON.productContent}</Typography>
              </StartPointBox>
              <StartPointBox>
                <img src={campaignIcon} className="start_img" />
                <Typography className="start_point_content">{configJSON.campaignContent}</Typography>
              </StartPointBox>
              <StartPointBox>
                <img src={subcriberIcon} className="start_img" />
                <Typography className="start_point_content">{configJSON.subcriberContent}</Typography>
              </StartPointBox>
              <StartPointBox>
                <img src={blogIcon} className="start_img" />
                <Typography className="start_point_content">{configJSON.blogContent}</Typography>
              </StartPointBox>
            </StartPointContainer> 
            <GuideContainer>
            <GuideContent>
             {configJSON.GuideContent}
             <span className="guided_content">{configJSON.GuidedContent}</span>
            </GuideContent>
            <GuideButton>
              {configJSON.GuideBtnContent}
            </GuideButton>
            </GuideContainer>        
          </GenerateBox>
        </MainContainer>
      </>)
    }
    
    renderGenerateChatPage = () => {
      return(<>
      <Box style={webStyles.mainContainer}>
        <Box style={webStyles.topMsgBar}>
          <img onClick={this.handleChatPageBack} data-testId="backChatPageTestID" style={webStyles.topMsgBarIcon} src={btnbackIcon} alt="" />
          <Typography  style={webStyles.topMsgBarTxt}>Pet food 50& off campaign|</Typography>
          <img style={webStyles.topMsgBarIcon} src={editIcon} alt="" />
        </Box>
        <Box style={webStyles.chatBox} >
          <Box style={webStyles.userChatMainBox}>
            <Typography style={webStyles.yourQuesTxt}>Your question </Typography>
            <Box  style={webStyles.userChatBox}>
              <Typography style={webStyles.userChat}>
                I run a online pet store. Create a facebook and an instagram post advertising 50% off dog food this Friday. Schedule it to post at 1pm on Thursday.
                I run a online pet store. Create a facebook and an instagram post advertising 50% off dog food this Friday. Schedule it to post at 1pm on Thursday.
                I run a online pet store. Create a facebook and an instagram post advertising 50% off dog food this Friday. Schedule it to post at 1pm on Thursday.
                I run a online pet store. Create a facebook and an instagram post advertising 50% off dog food this Friday. Schedule it to post at 1pm on Thursday.
              </Typography>
            </Box>
          </Box>
          <Box style={webStyles.jarvysChatBox}>
            <Typography style={webStyles.jarvysText}>JARVYS’s answer</Typography>
            <Typography style={webStyles.jarvysAnsText}>
              Hi there!
              I'd be happy to help you create Facebook and Instagram posts for your online pet store's 50% off dog food promotion. To ensure the content is successful, could you please provide the following information:

              Product Details: Which dog food brand(s) and product(s) are included in the sale? Are there specific features or benefits you’d like to highlight?
              Promotion Details: Any specific terms and conditions for the 50% off sale that should be included? (e.g., online only, while supplies last, etc.)
              Visual Assets: Do you have any specific images, logos, or videos you'd like to use for these posts?
              Target Audience: Is there a particular audience you’re aiming to reach? (e.g., new customers, returning customers, specific demographics)
              Tone and Style: Any specific tone or style you prefer for the posts? (e.g., fun and playful, professional and informative)
              Additional Information: Any other details you think might be important for creating these posts?

              Once I have this information, I can generate engaging and effective posts for both Facebook and Instagram. Thank you!
            </Typography>
            <Box style={webStyles.jarvysAnsIconBox}>
              <img style={webStyles.jarvysIcon} src={speakerIcon} alt="" />
              <img style={webStyles.jarvysIcon} src={reloadIcon} alt="" />
              <img style={webStyles.jarvysIcon} src={dislikeIcon} alt="" />
            </Box>
          </Box>
          <Box style={webStyles.userChatMainBox}>
            <Typography style={webStyles.yourQuesTxt}>Your question</Typography>
            <Box  style={webStyles.userChatBox}>
              <Typography style={webStyles.userChat}>
                I run a online pet store. Create a facebook and an instagram post advertising 50% off dog food this Friday. Schedule it to post at 1pm on Thursday.
                I run a online pet store. Create a facebook and an instagram post advertising 50% off dog food this Friday. Schedule it to post at 1pm on Thursday.
                I run a online pet store. Create a facebook and an instagram post advertising 50% off dog food this Friday. Schedule it to post at 1pm on Thursday.
                I run a online pet store. Create a facebook and an instagram post advertising 50% off dog food this Friday. Schedule it to post at 1pm on Thursday.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box style={webStyles.inputBox}>
        <StyledInputField
            fullWidth
            id="outlined-adornment-password"
            type={'text'}
            placeholder='Enter your text'
            variant='outlined'
            InputProps={{
              endAdornment: <IconButton
                aria-label="toggle password visibility"
                data-test-id='btnSendMsg'
                onClick={this.handleOpenModal}
                edge="end"
              >
                <img src={sendIcon} alt="" />
              </IconButton>
            }}
          />
        </Box>
        <StyledDialog
            onClose={this.handleCloseModal}
            aria-labelledby="customized-dialog-title"
            open={this.state.isModalOpen}
        >
            <IconBtn
                aria-label="close"
                data-test-id='btnCloseModal'
                onClick={this.handleCloseModal}
            >
                <img src={closeIcon} alt="close_icon" />
            </IconBtn>
            
            <DialogContent dividers>
              <DottedCircularLoader/>
              <Typography style={webStyles.modalTxt}>Generating your content...</Typography>
            </DialogContent>
        </StyledDialog>
      </Box>
      </>)
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <BreadcrumbNavigationPage navigation={this.props.navigation}>
          {!this.state.isChatPage ?
            this.renderGenerateContent() :
            this.renderGenerateChatPage()
          }
        </BreadcrumbNavigationPage>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  mainContainer: {
    margin: '32px 50px 0 24px'
  },
  topMsgBar: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '24px',
  },
  topMsgBarIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  topMsgBarTxt: {
    width: 'fit-content',
    background: '#EFEFEF',
    color: '#4D4D4D',
    border: '0.5px solid #000000',
    padding: '2px 4px 2px 4px',
    margin: '0 20px 0 20px',
  },
  chatBox: {
    margin: '0 20px 0 20px',
  },
  userChatMainBox: {
    marginTop: '24px',
  },
  yourQuesTxt: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#4D4D4D',
    textAlign: 'right' as 'right'
  },
  userChatBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    marginTop: '8px',
  },
  userChat: {
    width: "67.42%",
    boxSizing: 'border-box' as 'border-box',
    background: '#FFFDD8',
    padding: "8px 16px 8px 16px",
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#4D4D4D',
    borderRadius: '16px 0px 16px 16px',
  },
  jarvysChatBox: {},
  jarvysText: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '24px',
    color: '#4D4D4D',
  },
  jarvysAnsText: {
    width: "67.42%",
    marginTop: '8px',
    boxSizing: 'border-box' as 'border-box',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#4D4D4D',
  },
  jarvysAnsIconBox: {
    marginTop: '14px',
    width: 'fit-content',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    columnGap: '14px',
  },
  jarvysIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  inputBox: {
    margin: '20px 50px 20px 50px'
  },
  modalTxt: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center' as 'center',
    color: '#000000',
    marginTop: '55px',
  },
 
}
    
const StyledDialog = styled(Dialog) ({
  "& .MuiDialog-paper": {
    width: '529px',
    borderRadius: "12px",
    boxSizing: 'border-box',
  },
  '& .MuiDialogContent-root': {
      paddingTop: '48px',
      paddingBottom: '48px',
  },
});

const IconBtn = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});

const StyledInputField = styled(TextField)({
  marginBottom: "0px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#B3B3B3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B3B3B3",
      borderWidth: "1px",
    },
  },
  "& .MuiInputBase-root":{
    fontFamily: 'Poppins',
    height: '66px',
    margin: 'auto',
    borderRadius: '100px',
    backgroundColor: "#FFFFFF",
    fontSize: '16',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderRadius: "100px",
    borderColor: "#B3B3B3",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#B3B3B3",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: 'Poppins',
    color: '#B3B3B3',
    paddingLeft: '20px',
    paddingTop: "17px",
    "&::placeholder": {
      fontSize: "16",
      fontFamily: 'Poppins',
      color: '#B3B3B3',
    },
    "&:not(:placeholder-shown)": {
      fontFamily: 'Poppins',
      color: '#B3B3B3',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    color:'red',
    textTransform:'uppercase',
    lineHeight: "19.2px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "Lato",
    color: "#DC2626",
  }, 
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B3B3B3"
  },
  
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"1px solid #FFFFFF",
  },
  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
});

const MainContainer = styled(Box)({
  height: "100%",
  backgroundColor: "#FFFDD8",
  padding : "100px",
  overflow: "scroll",
  "@media (max-width: 1000px)": {
    padding : "50px 20px"
  },
  "@media (max-width: 600px)": {
    padding : "50px 20px"
  },
})

const GenerateBox = styled(Box)({
  width: "100%"
})

const HeadingContent = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "32px",
  color: "#4D4D4D"
 })

const InputFieldBox = styled(Box)({
  width: "100%",
  height: "65px",
  marginTop: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "100px",
  border: "1px solid #DDDDDDDD",
  backgroundColor: "#FFFFFF",
  overflow: "hidden",
  cursor: "pointer",
  "& .textField":{
    width: "100%",
    backgroundColor: "#FFFFFF",
  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":{
    borderStyle: "unset"
  }
  },
  "& .search_img": {
    height: "20px",
    marginLeft: "15px"
  },
  "& .textFieldBtn": {
    backgroundColor: "#000000",
    width: "200px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    color:"#FFFFFF",
    justifyContent: "center",
    gap: "10px",
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "18px",  
    cursor : "pointer"
  },
  "& .star_img":{
    height: "20px"
  },  
 })

 const ExampleContent = styled(Box)({
  padding: "0 50px",
  marginTop: "35px",
  fontFamily: "Open Sans",
  fontWeight: 300,
  fontSize: "16px",
  fontStyle:"italic"
})

const ContentHeading = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "18px",
  color: "#4D4D4D",
  marginTop: "80px"
 })

 const StartPointContainer = styled(Box)({
   display: "flex",
   marginTop: "30px",
   justifyContent: "space-between",
   flexWrap: "wrap",
   gap: "20px"
 })

 const StartPointBox = styled(Box)({
   flex: "1 0 150px",
   border: "0.5px solid #000000",
   borderRadius: "20px",
   padding: "16px",
   textAlign: "center",
   backgroundColor: "#FFFEE1",
   cursor: "pointer",
   "& .start_img": {
     height: "24px",
     color: "#3D3D3D"
   },
   "& .start_point_content": {
     marginTop: "15px",
     fontFamily: "Open Sans",
     fontWeight: 400,
     fontSize: "16px",
     color: "#4D4D4D",
   }
 })

const GuideContainer = styled(Box)({
   display: "flex",
   justifyContent: "space-between",
   alignItems:"center",
   flexWrap: "wrap",
   marginTop: "45px",
   gap: "20px"
})

const GuideContent = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "16px",
  color: "#4D4D4D",
  "& .guided_content": {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#4D4D4D",
    marginLeft: "5px"
  }
})

const GuideButton = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "16px",
  color: "#FFFFFF",
  backgroundColor: "#000000",
  padding: "10px 16px",
  borderRadius: "8px",
  cursor: "pointer"
})
// Customizable Area End 

// Customizable Area End
