import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  children:React.ReactNode
  // Customizable Area End
}
interface Children {
  id: number;
  page: string;
}

interface S {
  // Customizable Area Start
  currentChildren: Children[];
  currentBreadcrumbNavigation: (string | number)[];
  txtInputPageName: string;
  menuToggle: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BreadcrumbNavigationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDocumentId: string = "";
  getDocumentFetchBreadcrumbData: string = "";
  currentGetBreadData: string[] = [];
  currentPageId: string | number | undefined = Infinity;
  postDocumentId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentChildren: [],
      currentBreadcrumbNavigation: [Infinity],
      txtInputPageName: "",
      menuToggle: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJsonSuccess = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJsonSuccess &&
      apiRequestCallId === this.getDocumentId &&
      (responseJsonSuccess.data || responseJsonSuccess.length >= 0)
    ) {
      const dataCurrent =
        responseJsonSuccess.length >= 0
          ? responseJsonSuccess
          : responseJsonSuccess.data.attributes.childern;
      this.getDataSuccess(dataCurrent);
      return;
    }

    if (
      responseJsonSuccess &&
      apiRequestCallId === this.postDocumentId &&
      responseJsonSuccess.data
    ) {
      this.getBreadcrumbData(this.currentPageId);
      return;
    }

    this.currentGetBreadData.forEach((breadApiId, index) => {
      if (apiRequestCallId === breadApiId && responseJsonSuccess.data) {
        this.updateBreadcrumbState(responseJsonSuccess, index);
      }
    });
    // Customizable Area End
  }

  // web events
  setInputPageName(pageName: string) {
    this.setState({
      txtInputPageName: pageName,
    });
  }

  // Customizable Area Start
  updateBreadcrumbState(
    responseJsonSuccess: { data: { attributes: { page: string; id: number } } },
    index: number
  ) {
    let dataCurrent = [];
    const current = this.state.currentBreadcrumbNavigation;
    dataCurrent = [...current];
    dataCurrent[
      index + 1
    ] = `${responseJsonSuccess.data.attributes.page}-${responseJsonSuccess.data.attributes.id}`;
    this.setState({
      currentBreadcrumbNavigation: dataCurrent,
    });
  }

  updateBreadcrumbNavigation() {
    this.setState(
      {
        currentBreadcrumbNavigation: [Infinity],
      },
      () => {
        const location = this.props.navigation.state.routeName;
        const breadcrumbId = location
          .split("/")
          .filter((filter: string) => filter)
          .slice(1)
          .slice(-3)
          .map((element: string) => {
            const stringToId = element.split("-");
            return stringToId[stringToId.length - 1];
          });

        breadcrumbId.forEach((breadId: string) => {
          const headers = {
            token:
              "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzksImV4cCI6MTY3NjM1MzE3Nn0.UzFJ2R5Uuyg12KkuX94O3dt2fn7nSYiR7qxLOR3xNnZyDlQeIFrYmNrzKyIjznxFS9jSSFUGGeMbGS3AgoyyUw",
          };

          const getMessage: Message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );

          this.getDocumentFetchBreadcrumbData = getMessage.messageId;

          this.currentGetBreadData.push(this.getDocumentFetchBreadcrumbData);

          getMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_breadcrumbnavigation/breadcrumbnavigations/${breadId}`
          );

          getMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );

          getMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
          );

          runEngine.sendMessage(getMessage.id, getMessage);
        });
      }
    );
  }

  addPageOnPage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const header = {
      "Content-Type": "application/json",
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzksImV4cCI6MTY3NjM1MzE3Nn0.UzFJ2R5Uuyg12KkuX94O3dt2fn7nSYiR7qxLOR3xNnZyDlQeIFrYmNrzKyIjznxFS9jSSFUGGeMbGS3AgoyyUw",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postDocumentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_breadcrumbnavigation/breadcrumbnavigations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    if (this.currentPageId === Infinity) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            page: this.state.txtInputPageName,
          },
        })
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            page: this.state.txtInputPageName,
            parent_id: this.currentPageId,
          },
        })
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDataSuccess(children: Children[]) {
    this.setState({
      currentChildren: children,
    });
  }

  getBreadcrumbData(pageId: string | number | undefined) {
    this.currentGetBreadData = [];

    this.setState({
      txtInputPageName: "",
    });

    const headers = {
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzksImV4cCI6MTY3NjM1MzE3Nn0.UzFJ2R5Uuyg12KkuX94O3dt2fn7nSYiR7qxLOR3xNnZyDlQeIFrYmNrzKyIjznxFS9jSSFUGGeMbGS3AgoyyUw",
    };

    const getMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDocumentId = getMessage.messageId;

    if (pageId !== Infinity) {
      getMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_breadcrumbnavigation/breadcrumbnavigations/${pageId}`
      );
    } else {
      getMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_breadcrumbnavigation/breadcrumbnavigations"
      );
    }

    getMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(getMessage.id, getMessage);
  }

  handleToggleDrawer = () =>{
    this.setState({menuToggle : false});
}

  goToGenerateContent = () => {
    const contentResult = new Message(getName(MessageEnum.NavigationMessage));
    contentResult.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "Chatgpt"
    );
    contentResult.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(contentResult);
}

  goToInteractivefaqs = () => {
    const interactivefaq = new Message(getName(MessageEnum.NavigationMessage));
    interactivefaq.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Interactivefaqs"
    );
    interactivefaq.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(interactivefaq);
  }

  goToOnboardingguide = () => {
    const interactivefaq = new Message(getName(MessageEnum.NavigationMessage));
    interactivefaq.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Onboardingguide"
    );
    interactivefaq.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(interactivefaq);
  }

  goToConnectedAccount = () => {
    const connectedAccount = new Message(getName(MessageEnum.NavigationMessage));
    connectedAccount.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ConnectedAccounts"
    );
    connectedAccount.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(connectedAccount);
  }
  // Customizable Area End
}
