import React from "react";
// Customizable Area Start

 // Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// Merge Engine - Artboard Dimension  - End

import {Box,Typography, Dialog, styled } from "@mui/material";

import { rightArrow, cardPlaceholder ,mailIcon,bellIcon,avatar ,play, calender, cross} from "./assets";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import BreadcrumbNavigationPage from "../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web"

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans, Arial, sans-serif',
  },
});

// Customizable Area End

import OnboardingguideController, {
  Props,
  configJSON
} from "./OnboardingguideController";

export default class Onboardingguide extends OnboardingguideController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
      partialVisibilityGutter: 30
    },
    desktop: {
      breakpoint: { max: 2999, min: 1024 },
      items: 3,
      slidesToSlide: 1,
      partialVisibilityGutter: 30
    },
    laptop:{
      breakpoint: { max: 1023, min: 820 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 819, min: 560 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 559, min: 0 },
      items: 1,
      slidesToSlide: 1,
    }
  };

  renderVideoPlayer = () => {
    return (
      <StyledDialog open={this.state.showVideoPlayer} style={webStyle.videoModal} data-test-id="video-modal" onClose={() => {this.setState({showVideoPlayer: false, videoModalData: {created_at: "", video_url: "", title: "", description: null}})}}>
        <Box style={{padding:'24px'}}>
          <Box display="flex" style={{justifyContent:"space-between"}}>
            <Typography className="modal-heading" style={{fontSize:'20px', fontWeight:600, fontFamily:'Open sans', color: '#4D4D4D'}}>{this.state.videoModalData.title}</Typography>
            <img src={cross} onClick={() => {this.setState({showVideoPlayer: false})}} data-test-id="video-modal-close-btn"/>
          </Box>
          <Typography>{this.state.videoModalData.description}</Typography>
          <Box sx={{marginTop:'12px'}}>
            <video src={this.state.videoModalData.video_url} style={{width:'100%', height:'auto', borderRadius:'12px'}} controls/>
          </Box>
        </Box>
      </StyledDialog>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      <ThemeProvider theme={theme}>
      <Box display="flex" flexDirection="row">
        <BreadcrumbNavigationPage navigation={this.props.navigation}>
        <style>
          {`
            .carousel-container{
              width:891px;
              position:unset;
            }
            .videoWrapper{
              gap: 12px;
              flex-wrap: wrap;
            }
            .video{
              width: 549px;
            }
            .modal-heading{
              font-size: 20px;
              font-weight: 600;
              color: #4D4D4D;
              font-family: Open sans;
              text-transform: capitalize;
            }
            .f24w{
              color: #fff;
              font-size:24px;
              font-family: Open sans;
              font-weight: 700;
            }
            .f16w{
              color: #fff;
              font-size: 16px;
              font-weight: 600;
              font-family: Open sans;
            }
            .textHolder{position:absolute; top:60%; left:4%; width:550px; text-align:start}
            .clamped-text {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #4D4D4D;
              margin-bottom: 8px;
              font-family: Open sans;
            }
            .react-multi-carousel-item {
              width: unset !important;
            }
            .react-multi-carousel-dot-list{
              bottom:-24px;
            }
            @media( max-width: 1024){
              .carousel-container{
                width:600px;
                position:unset;
              }
              .video{
                width: 500px;
              }
            }
            .react-multiple-carousel__arrow--right{
              right:0;
            }
            .react-multiple-carousel__arrow--left{
              left:0;
            }

            .react-multi-carousel-item {
              display: grid !important;
              place-content: center !important;
            }
           

            @media (min-width: 1024px) and (max-width: 3000px){
              .subHeading {
                font-size: 32px;
                color: #4D4D4D;
              }
              .video{
                width: 500px;
              }
              .f24w{
                color: #fff;
                font-size:24px;
                font-family: Open Sans;
                font-weight: 700;
              }
              .f16w{
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                font-family: Open Sans;
              }
            }
            
            @media (min-width: 820px) and (max-width: 1023px) {
              .subHeading {
                font-size: 20px;
                color: #4D4D4D;
                margin-left: 1%;
              }
              .videoWrapper{
                justify-content: center;
              }
              .video{
                width: 480px;
              }
              .f24w{
                color: #fff;
                font-size:24px;
                font-family: Open Sans;
                font-weight: 700;
              }
              .f16w{
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                font-family: Open Sans;
              }
            }
            
            @media (min-width: 560px) and (max-width: 819px) {
              .subHeading {
                // margin-left: 3%;
                color: #4D4D4D;
              }
              .react-multi-carousel-item {
                width: 327px;
              }
              .videoWrapper{
                justify-content: center;
              }
              .video{
                width: 400px;
              }
              .f24w:{
                font-size:20px;
              }
              .f16w: {
                font-size: 16px;
              }
              .textHolder{width:420px; top:40%}
            }
            @media (min-width: 420px) and (max-width: 559px) {
              .modal-heading:{
                font-size: 16px;
              }
              .subHeading {
                text-align:center;
                color: #4D4D4D;
              }
              .videoWrapper{
                justify-content: center;
              }
              .video{
                width: 325px;
              }
              .f24w{
                fontSize: 16px;
              }
              .f16w{
                font-size: 14px;
              }
              .textHolder{width:420px; top:40%}
              .react-multi-carousel-list { justify-content: center }
            }
            @media (min-width: 0px) and (max-width: 419px) {
              .subHeading {
                text-align:center;
                color: #4D4D4D;
              }
              .videoWrapper{
                justify-content: center;
              }
              .video{
                width: 325px;
              }
              .f24w{
                font-size: 16px
              }
              .f16w{
                font-size: 14px;
              }
              .textHolder{width:350px; top:30%}
              .react-multi-carousel-list { justify-content: center }
            }
          `}
        </style>
        <Box style={webStyle.tutorialContainer}>
          <Box display="flex" flexDirection="row" style={webStyle.header}>
            <img src={mailIcon} />
            <img src={bellIcon} />
            <Typography style={{ color: "#000" }}>
              Hi, Eugene Clark
            </Typography>
            <img src={avatar} />
          </Box>

          <Box display="flex" flexDirection="row" style={webStyle.pathText}>
            <Typography style={{ color: "#909090", fontFamily: 'Open sans' }}>
              Dashboard
            </Typography>
            <Typography>
              <img src={rightArrow} />
            </Typography>
            <Typography style={ this.state.openArticle.length == 0 ? { color: "#000", fontFamily: 'Open sans' } : {color:'#909090', cursor:'pointer', fontFamily: 'Open sans'}} onClick={() => {this.setState({openArticle:[]})}} data-test-id="quickstart-link">
              QuickStart Tutorial
            </Typography>
            {this.state.openArticle.length != 0 && <Typography><img src={rightArrow}/></Typography>}
            {this.state.openArticle.length != 0 && <Typography style={{ color: "#000", textTransform:'capitalize', fontFamily:'Open Sans' }}>{this.state.openArticle[0].title}</Typography>}
          </Box>
          {this.state.openArticle.length === 0 ? <Box>
            <Box style={webStyle.bannerContainer as React.CSSProperties} onClick={() => {this.setState({showVideoPlayer: true, videoModalData: {
              created_at: "",
              title: "DatAI Keynote: Introduction",
              description: "The keynote depicts the basic principles of the DatAI company that align with the basics of the qualitative marketing services while using AI tools.",
              video_url: this.state.tutorialData.attributes.primary_video
            }})}}>
              <video src={this.state.tutorialData.attributes.primary_video} style={webStyle.bannerImg}></video>
              <img src={play} style={{ position: "absolute", top: "45%", left: "45%", }} />
              <Box className="textHolder">
                <Typography className="f24w">DatAI Keynote: Introduction</Typography>
                <Typography className="f16w">The keynote depicts the basic principles of the DatAI company that align with the basics of the qualitative marketing services while using AI tools.</Typography>
                <Box style={{ borderRadius:'8px', backgroundColor:'#00000080', padding:'4px', width:'60px' }}>
                  <Typography style={{color:'#fff', fontSize:"14px", fontWeight:700, fontFamily:'Open sans'}}>12.34.44</Typography>
                </Box>
              </Box>
            </Box>

            <Box style={{marginTop:'48px'}}>
              <Typography className="subHeading" style={webStyle.subHeading as React.CSSProperties}>Additional Support Video</Typography>
              <Box width="100%" position="relative" display="flex" className="videoWrapper">
                {this.state.tutorialData.attributes.support_videos.map((item, index) => (
                  <Box key={item.created_at} onClick={() => {this.setState({showVideoPlayer: true, videoModalData: item})}} data-test-id={`videos-${index}`}>
                    <Box style={{ position: "relative" }}>
                      <video src={item.video_url} height="auto" className="video" style={{borderRadius:"8px"}}/>
                      <Box style={{ position: "absolute", bottom: "4%", right: "4%", borderRadius:'8px', backgroundColor:'#00000080', padding:'4px' }}>
                        <Typography style={{color:'#fff', fontSize:"14px", fontWeight:700, fontFamily:'Open sans'}}>12.34.44</Typography>
                      </Box>
                    </Box>
                    <Typography style={{...webStyle.cardHeading as React.CSSProperties, color: '#232323'}}>{item.title}</Typography>
                    <Typography style={webStyle.f16 as React.CSSProperties}>{this.formatDate(this.state.tutorialData.attributes.created_at)}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box sx={webStyle.caraouselContainer}>
              <Typography className="subHeading" style={webStyle.subHeading as React.CSSProperties}>You may also be interested in</Typography>
              <Box width="100%" position="relative" display="flex">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={this.responsive}
                  renderDotsOutside
                  containerClass="carousel-container"
                  dotListClass="custom-dot-list-style"
                  partialVisible
                  infinite={false}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  renderArrowsWhenDisabled
                >
                  {this.state.tutorialData.attributes.related_articles.map((item, index) => (
                    <Box sx={webStyle.videoBox} key={index} onClick={() => {this.showArticle(item)}} data-test-id={`article-${index}`}>
                      <img src={item.image_url || cardPlaceholder} alt={`Placeholder ${item.title}`} height="auto" width="327px" style={{borderRadius:"8px"}}/>
                      <Typography
                        style={webStyle.cardHeading as React.CSSProperties}>
                        {item.title}
                      </Typography>
                      <Box>
                        <Typography className="clamped-text" dangerouslySetInnerHTML={{ __html: item.content }}
                          style={webStyle.cardSubHeading as React.CSSProperties} sx={{fontSize:'16px'}}>
                        </Typography>
                      </Box>
                      <Box display="flex" style={{gap:'4px', marginTop:'8px'}}>
                        <Box>
                          <img src={calender} height='auto' width="16px" />
                        </Box>
                        <Typography
                          style={webStyle.f14 as React.CSSProperties}>
                            {this.formatDate(this.state.tutorialData.attributes.created_at)}
                        </Typography>
                        <Typography style={{marginLeft:'auto', fontFamily:'Open sans', color:'#B3B3B3', fontSize:'14px', fontWeight:400}}>10 minutes to read</Typography>

                      </Box>
                    </Box>
                    
                  ))}
                </Carousel>
              </Box>
            </Box>

            <Box style={{marginTop:'200px', marginBottom:'50px'}}> 
              <Typography style={{color:'#7D7D7D', fontSize:'14px', fontWeight: 600}}>DatAI © 2020-2024</Typography>
              <Box display="flex" style={{gap:'16px'}}>
                <Typography style={{color:'#8F8F8F', fontSize:'12px', fontWeight: 400}}>Privacy policy</Typography>   
                <Typography style={{color:'#8F8F8F', fontSize:'12px', fontWeight: 400}}>Terms & Conditions</Typography>
              </Box>
            </Box>
          </Box> : <Box>
            <Box style={{width:'100%', height:'225px'}}><img src={this.state.openArticle[0].image_url || cardPlaceholder} style={{objectFit:'cover', width:'100%', height:'100%', borderRadius:'16px'}}/></Box>
            <Box display="flex" style={{justifyContent:'space-between', marginTop:'16px'}}>
              <Styled40Text>{this.state.openArticle[0].title}</Styled40Text>
              <Typography style={webStyle.f16}>12.02.2024</Typography>
            </Box>
            <Typography dangerouslySetInnerHTML={{ __html: this.state.openArticle[0].content }}></Typography>
            </Box>}
          {this.state.showVideoPlayer && this.renderVideoPlayer()}
        </Box>
        </BreadcrumbNavigationPage>
      </Box>
      </ThemeProvider>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm":{
    width:'100%',
    borderRadius: '16px',
  },
  "& .MuiDialog-paper": {
    margin: '0'
  },
  "& .MuiDialog-paperScrollPaper":{
  }
})

const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: '16px',
});

const Styled40Text = styled(Typography)({
  fontSize:'40px',
  fontFamily:'Open Sans',
  fontWeight:700,
  textTransform:'capitalize',
  '@media (max-height: 615px)': {
    fontSize:'32px !important',
  },
  '@media (max-height: 530px)': {
    fontSize:'24px important',
  },
  '@media (max-height: 430px)': {
    fontSize:'18px !important',
  },
  '@media (max-height: 350px)': {
    fontSize:'16px !important',
  },
})

const webStyle={
  videoModal:{
    padding:'16px',
    borderRadius:'16px',
    ".MuiDialog-paperWidthSm": {
      width:'80%',
      borderRadius: '16px',
    }
  },
  videoBox:{
    width: "327px",
    gap:'6px',
  },
  videoElement:{
    width: "327px",
    height: "149px",
    borderRadius:'8px',
  },
  caraousel:{
    display : "flex",
    flexDirection:"row",
    alignItems:"center",
    height:'300px',
    justifyContent:"center",
    gap: "40px",
  },
  subHeading: {
    fontWeight: 700,
    lineHeight: "36.77px",
    marginBottom:'12px',
    fontFamily: 'Open sans'
  },
  bannerImg: {
    width: '100%',
    maxHeight: "464px",
    borderRadius:"12px",
    objectFit:'cover' as 'cover'
    
  },
  tutorialContainer:{
    width: "calc(100%-48px)",
    // height: "1280px",
    Top: "164px",
    Left: "324px",
    gap: "54px",
    alignItems:"center",
    justifyContent:"center",
    margin:"0 auto",
    padding:'24px',
    fontFamily:'Open sans'
  },
  cardHeading:{
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "27.24px",
    textAlign: "left",
    margin:0,
    padding:"10px 0",
    color:'#4D4D4D',
    textTransform: "capitalize",
    fontFamily:'Open sans'
  },
  cardSubHeading:{
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16.34px",
    textAlign: "left",
    margin: 0,
    padding:0,
    fontFamily: 'Open sans',
    color:'#4D4D4D',
  },
  cardDate:{
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "14.98px",
    textAlign: "left",
    margin:"10px 0",
    padding:0,
    fontFamily: 'Open sans'
  },
  caraouselContainer:{
    height:"301px",
    marginTop:"54px"
  },
  bannerContainer:{
    textAlign:"center",
    position: 'relative',
    objectFit:'cover'

  },
  pathText: {
    alignItems: "center",
    height: "26px",
    top: "106px",
    left: "293px",
    gap: "4px",
    opacity: "0px",
    margin : "20px 10px",
    fontFamily: 'Open sans'
  },
  header:{
    height: "26px",
    top: "106px",
    left: "293px",
    gap: "16px",
    opacity: "0px",
    margin : "20px 10px",
    alignItems: "center" , 
    justifyContent:"flex-end" 
  },
  f14:{
    fontSize:'14px',
    fontWeight:400,
    color:'#B0B0B0',
    lineHeight:'21px',
    fontFamily: 'Open sans'
  },
  f16:{
    fontSize:'16px',
    fontWeight:400,
    color:'#B0B0B0',
    lineHeight:'21px',
    fontFamily: 'Open sans'
  },
  f20:{
    fontSize:'20px',
    fontWeight:600,
    color:'#232323',
    lineHeight:'28px',
    fontFamily: 'Open sans'
  },
  f32:{
    fontSize:'32px',
    color:'#4D4D4D',
    fontWeight:700,
    lineHeight:'43.5px',
    fontFamily: 'Open sans'
  }
}
// Customizable Area End
