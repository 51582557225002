// Customizable Area Start
import React from "react";
import {
Box,
Typography,
Button,
TextField,
Backdrop,
Modal,


} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {  facebooklogo, instagramlogo, twitterlogo, tiktoklogo, magnifylicon,bgwhitefacebooklogo,bgwhiteinstagramlogo,bgwhitetwitterlogo,bgwhitetiktoklogo,Loader,buttondesign} from "./assets";
import styled from '@emotion/styled';
const themes = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import LandingPageController, {
Props,
} from "./LandingPageController";
import EmailAccountRegistration from "../../email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import VideoBackgrounds from "../../videobackgrounds/src/VideoBackgrounds.web"
import ForgetPassword from "../../forgot-password/src/ForgetPassword.web";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import HeaderTopNavigation from "../../../components/src/HeaderTopNavigation"
import Footerbar from "../../../components/src/Footerbar"

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);

  }
backGround = () => {
return(
    <>
     <Box style={{  display: 'flex', justifyContent: 'space-between', width: '200px'}}  >
                <img src={this.state.isBackgroundVideoAvailable ?bgwhitetwitterlogo : twitterlogo} alt="twitterogo" style={{ alignItems: 'center' }} />
                <img src={this.state.isBackgroundVideoAvailable ?bgwhitefacebooklogo :facebooklogo} alt="faceboologo" style={{ alignItems: 'center' }} />
                <img src={this.state.isBackgroundVideoAvailable ?bgwhiteinstagramlogo:instagramlogo} alt="instagramlogo" style={{ alignItems: 'center' }} />
                <img src={this.state.isBackgroundVideoAvailable ?bgwhitetiktoklogo: tiktoklogo} alt="tiktoklogo" style={{ alignItems: 'center' }} />
              </Box>
    </>
  )

}
privacyPolicy = () => {
return(
    <>
      <Box>
        <Box style={{ display: 'flex' }}>
          <Typography style={{ marginRight: '20px', fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', marginBottom: '20px', color: this.state.isBackgroundVideoAvailable ? '#FFFFFF ' : '#000000' }}>
            Privacy policy
          </Typography>
          <Typography style={{ fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', marginBottom: '20px', color: this.state.isBackgroundVideoAvailable ? '#FFFFFF ' : '#000000' }}>
            Terms & Conditions
          </Typography>
        </Box>
      </Box>
    </>
  )
}


Gradienttext=()=>{
return(
<>
    <StyledTextGradient
      style={{
        background: this.state.isBackgroundVideoAvailable ? 'none' : 'linear-gradient(to right, #0066FF, #E8CC17)',
        WebkitBackgroundClip: this.state.isBackgroundVideoAvailable ? 'none' : 'text',
        WebkitTextFillColor: this.state.isBackgroundVideoAvailable ? 'white' : 'transparent',
      }}>Talk with JARVYS</StyledTextGradient>
  </>
)

}



render() {
  return (
      <ThemeProvider theme={themes}>
        <VideoBackgrounds id="landingPageBackGround" navigation={this.props.navigation}>
          <HeaderTopNavigation isActive={this.state.isBackgroundVideoAvailable} 
           handlelogin={this.handleOpenLogInForm.bind(this) }
            handlesignup={this.handleOpenSignUpForm.bind(this)} 
            data-testid="headercomponent" />
        <Box sx={outer}>
        <StyleLandingContainer> 
           

            <Box  display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}height={'90vh'}>
            <Box>
              <StyledCenterText color={this.state.isBackgroundVideoAvailable?'#FFFFFF ':'#4D4D4D'}>
                Marketing Made Easy</StyledCenterText>
            </Box>
              {this.Gradienttext()}
              <StyleJarvysParentBoxInput >
                <StyleJarvysInput style={{border:this.state.isBackgroundVideoAvailable? '#FFFFFF' :'2px solid #434343'}} >
                {!this.state.userMessage && (
                  <img  src={magnifylicon} alt="magnicon" style={{marginLeft:'12px'}} />
                )}
                  <TextField
                    placeholder="Talk with JARVYS"
                    data-testid={"messageInput"}
                    fullWidth
                    multiline
                    variant="standard"
                    sx={{
                      "& fieldset": { border: 'none' },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      
                      style: {
                        fontFamily:'Open Sans',
                        fontSize:'20px',
                        fontWeight:600,
                        border: 'none',
                        width: '100%',
                        padding:'20px',
                        color: this.state.isBackgroundVideoAvailable?'#000000':'rgba(0, 0, 0, 0.4)',
                        height: '100%',
                      },
                    }}
                    value={this.state.userMessage}
                    onChange={this.handleUserMessageChange.bind(this)}
                  />
                </StyleJarvysInput>
                <StyleJarvysBoxButton 
                  data-test-id="goToHomeButton"
                  data-testid="askToJarvysButton"
                  style={{
                   background:this.state.isBackgroundVideoAvailable ? 'linear-gradient(to right, #0066FF, #E8CC17)':'#202020',
                   
                  }}
                  test-data={this.state.userMessageResponse}
                  onClick={this.askToJarvys.bind(this)}> 
                  Ask JARVYS<img 
    src={buttondesign}
    alt="Icon" 
    style={{ marginLeft: '8px' }} 
  />
                </StyleJarvysBoxButton>
              </StyleJarvysParentBoxInput>
            </Box>
            
            <Footerbar isActive={this.state.isBackgroundVideoAvailable}/>
            <Backdrop open={this.state.isOpenSignUpForm} onClick={this.handleCloseSignUpForm.bind(this)} data-testid='signUpBackdrop'>
              {this.state.isOpenSignUpForm && <EmailAccountRegistration
                id="signUpForm"
                navigation={this.props.navigation}
                data-testid='signUpForm'
                />}
            </Backdrop>
            <Backdrop open={this.state.isOpenLogInForm} onClick={this.handleCloseLogInForm.bind(this)} data-testid='loginUpBackdrop'>
              {this.state.isOpenLogInForm && <EmailAccountLoginBlock
                id="logInForm"
                onClose={this.handleCloseLogInForm.bind(this)}
                navigation={this.props.navigation}
                data-testid='logInForm'
                goToRegistration={this.handleOpenRegistrationFromLogIn.bind(this)}
                goToForgetPassword={this.handleOpenForgetPassword.bind(this)}
                />}
            </Backdrop>
            <ForgetPassword
              open={this.state.isOpenForgetPasswordForm}
              navigation={this.props.navigation}
              data-testid='forgetPassword'
              onClose={this.handleCloseForgetPasswordForm.bind(this)}
              goToLoginForm={this.handleOpenLogInForm.bind(this)}
            />
            </StyleLandingContainer>    
               </Box>
               <Modal data-testid="jarvysmodel"
                            open={this.state.jarvysmodelopenlanding}
                            aria-labelledby="email-verified-title"
                            aria-describedby="email-verified-description"
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Box style={{
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: 'center',
                                maxHeight: 'fit-content',
                                width: "fit-content",
                                margin: "10px",
                                position: "relative",
                            }}>

                                <StyledModelContainer>
                                    <StyledModelInnerContainer>
                                        <Box display={'flex'} justifyContent={'end'} >
                                            <IconButton onClick={this.handleclosejarvysmodel.bind(this)} data-testid="closejarvysmodelbutton">
                                                <CloseIcon style={{ color: '#0F172A' }} />
                                            </IconButton>

                                        </Box>
                                        
                                        <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={'2vw'} marginBottom={'3vw'} marginTop={'3vw'}>

                                                              <StyledModelTextGradient
                            style={{

                              background: 'linear-gradient(to right, #0066FF, #E8CC17)',
                              WebkitBackgroundClip:'text',
                              WebkitTextFillColor: 'transparent',
                            }}>JARVYS AI</StyledModelTextGradient>
                                        
                                        <Box
                                            sx={{
                                              width: '51px', 
                                              height: '50px',
                                              animation: 'rotate 2s linear infinite', 
                                            }}
                                          >
                                             <img   
                                             src={Loader}
                                            alt="Loading..."
                                            style={{
                                              width: '51px', 
                                              height: '50px',
                                              animation: 'rotate 2s linear infinite',                                              
                                            }}                                            
                                             />
                                          </Box>
                                          <ModelBottomTypo>JARVYS processes your question...</ModelBottomTypo>
                                      </Box>

                                    </StyledModelInnerContainer>
                                </StyledModelContainer>
                            </Box>
                        </Modal>
        </VideoBackgrounds>       
      </ThemeProvider>
    );
  }
}



const webStyles = {
  root: {
    textAlign: 'center',
    flexGrow: 1,
  },
  searchBox: {
    width: '80%',
  },
  gradientText: {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Georgia',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '96px'
  },
  button: {
    background: 'linear-gradient(to right, #0e88b3, #65a695, #95ab66)',
    color: 'white',
  },
  footer: {
    width: '100%',
    textAlign: 'center',
  },
  appBar: {
    boxShadow: 'none',
    color: '#000',
     background: 'transparent',
      position: 'relative',
       
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  menuItem: {
    fontFamily: 'sans-serif',
    margin: "2px",
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 600,
    color: 'white',
  },
  logo: {
    flexGrow: 1,
    textAlign: 'center',
  },

};

const StyledModelContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFFFFF",
  maxHeight: '85vh',
  width: "40vw",
  padding: "40px",
  overflowY: "auto",
  borderRadius: '12px',
  '@media (max-height: 615px)': {
      maxHeight: '82vh',
  },
  '@media (max-height: 530px)': {
      maxHeight: '80vh',
  },
  '@media (max-height: 430px)': {
      maxHeight: '76vh',
  },
  '@media (max-height: 350px)': {
      maxHeight: '71vh',
  },
  '@media (max-height: 290px)': {
      maxHeight: '65vh',
  },
  '@media (max-height: 250px)': {
      maxHeight: '50vh',
  },
  '@media (max-height: 200px)': {
      maxHeight: '40vh',
  },
  '@media (max-height: 100px)': {
      maxHeight: '25vh',
  },
  "::-webkit-scrollbar": {
      display: 'none',
  },
  "-ms-overflow-style": 'none',
  "scrollbar-width": 'none',
  
});



const StyledModelInnerContainer = styled(Box)({
  width: '100%',
  height: "100%",
  '@media (max-width: 858px)': {
      flexDirection: 'column-reverse',
      justifyContent: "start",
      gap: "0px",
  },
});



const StyledModelTextGradient=styled(Typography)({
  fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '64px',
  
  '@media (max-width: 858px)': {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    fontSize: '5vw',


  },'@media (max-width: 768px)': {
    fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '5vw',

  },'@media (max-width: 626px)': {
    fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '5vw',

  },
  '@media (max-width: 468px)': {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    fontSize: '5vw',
  
  },'@media (max-width: 386px)': {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    fontSize: '5vw',
  
  },
})



const ModelBottomTypo=styled(Typography)({
  
  fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '18px',
  color:'#515151',
  
  '@media (max-width: 858px)': {
    fontWeight: 600, color:'#515151',
    fontFamily: 'Open Sans',
    fontSize: '2vw',


  },'@media (max-width: 768px)': {
    fontWeight: 600,
  fontFamily: 'Open Sans', color:'#515151',
  fontSize: '2vw',

  },'@media (max-width: 626px)': {
    fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '2vw', color:'#515151',

  },
  '@media (max-width: 468px)': {
    fontWeight: 600,
    color:'#515151',
    fontFamily: 'Open Sans',
    fontSize: '2vw',
  
  },'@media (max-width: 386px)': {
    fontWeight: 600,
    color:'#515151',
    fontFamily: 'Open Sans',
    fontSize: '2vw',
  
  },
})
const outer ={

  maxHeight:'fit-content',
  width:"fit-content",

  position:"relative",
  display:'flex',
justifyContent:'center',
alignItems:'center',
}
const StyleLandingContainer = styled(Box)({

  width:'100vw',


    '@media (max-height: 615px)': {
      maxHeight:'82vh',
    },

    '@media (max-height: 530px)': {
      maxHeight:'80vh',
    },
    '@media (max-height: 430px)': {
      maxHeight:'76vh',
    },
    '@media (max-height: 350px)': {
      maxHeight:'71vh',
    },
    '@media (max-height: 290px)': {
      maxHeight:'65vh',
    },
    '@media (max-height: 250px)': {
      maxHeight:'50vh',
    },
    '@media (max-height: 200px)': {
      maxHeight:'40vh',
    },
    '@media (max-height: 100px)': {
      maxHeight:'25vh',
    },
 
});


const StyledCenterText=styled(Typography)({
  
  textAlign: 'center',
  fontSize: '2.5vw',
    fontWeight: 500,
     fontFamily: 'Open Sans',

  '@media (max-width: 1018px)': {
    textAlign: 'center',
    fontSize: '2.5vw',
     fontWeight: 500,
     fontFamily: 'Open Sans',
  },'@media (max-width: 1034px)': {
    textAlign: 'center',
     fontSize: '2.5vw',
    fontWeight: 500,
     fontFamily: 'Open Sans',
    marginTop:'120px',

  },
  '@media (max-width: 974px)': {
    marginTop:'120px',
     textAlign: 'center',
    fontWeight: 500,
     fontSize: '4vw',
     fontFamily: 'Open Sans',
  },'@media (max-width: 918px)': {
    textAlign: 'center',
    fontSize: '4vw',
     fontWeight: 500,
     fontFamily: 'Open Sans',
    marginTop:'130px',

  },
  '@media (max-width: 858px)': {
    textAlign: 'center',
     fontSize: '6vw',
    fontWeight: 500,
     fontFamily: 'Open Sans',
    marginTop:'40px',


  },'@media (max-width: 768px)': {
    textAlign: 'center',
     fontSize: '6vw',
    fontFamily: 'Open Sans',
    fontWeight: 500,
    marginTop:'130px',


  },'@media (max-width: 626px)': {
    textAlign: 'center',
    fontWeight: 500,
     fontFamily: 'Open Sans',
    fontSize: '6vw',
    marginTop:'140px',

  },
  '@media (max-width: 468px)': {
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'Open Sans',
     fontSize: '6vw',
    marginTop:'150px',

  },'@media (max-width: 386px)': {
    textAlign: 'center',
     fontWeight: 500,
    fontFamily: 'Open Sans',
    fontSize: '6vw',
    marginTop:'180px',

  },
 
})

const StyledTextGradient=styled(Typography)({
  
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
  WebkitBackgroundClip: 'text',
  fontFamily: 'Georgia',
  textAlign: 'center',
  fontSize: '5vw',

  '@media (max-width: 1034px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontFamily: 'Georgia',
  fontWeight: 700,
  textAlign: 'center',
  fontSize: '5vw',

  },'@media (max-width: 1018px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  fontWeight: 700,
  textAlign: 'center',
  fontFamily: 'Georgia',
  fontSize: '5vw',

  },
  '@media (max-width: 974px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Georgia',
    fontWeight: 700,
    WebkitBackgroundClip: 'text',
    textAlign: 'center',
    fontSize: '5vw',
  
  },'@media (max-width: 918px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  fontFamily: 'Georgia',
  fontWeight: 700,
  WebkitBackgroundClip: 'text',
  textAlign: 'center',
  fontSize: '5vw',

  },
  '@media (max-width: 858px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    fontFamily: 'Georgia',
    WebkitTextFillColor: 'transparent',
  fontWeight: 700,
  textAlign: 'center',
  fontSize: '8.392vw',


  },'@media (max-width: 768px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  fontWeight: 700,
  textAlign: 'center',
  fontFamily: 'Georgia',
  fontSize: '8.392vw',
  },'@media (max-width: 626px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  fontWeight: 700,
  textAlign: 'center',
  fontFamily: 'Georgia',
  fontSize: '8.392vw',

  },
  '@media (max-width: 468px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Georgia',
    fontWeight: 700,
    WebkitBackgroundClip: 'text',
    textAlign: 'center',
    fontSize: '8.392vw',
  
  },'@media (max-width: 386px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Georgia',
    WebkitBackgroundClip: 'text',
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '8.392vw',
  
  },
 
})


const StyleJarvysInput=styled(Box)({
  
  border: "2px solid #434343",
   display: 'flex',
    justifyContent: 'center',
     alignItems: 'center',
     width: '54%',
    borderTopLeftRadius:'100px',
    borderBottomLeftRadius:'100px', 
  minHeight:"60px",overflow:'auto', overflowY:'hidden',  
      maxHeight: '70px', backgroundColor:'#FFFFFF',

  '@media (max-width: 1018px)': {
    border: "2px solid #434343",
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   borderTopLeftRadius:'100px',
   borderBottomLeftRadius:'100px', 
      width: '54%',
      minHeight:"60px",
      maxHeight: '70px', backgroundColor:'#FFFFFF',

  },'@media (max-width: 1034px)': {
    border: "2px solid #434343",
   display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius:'100px',
    borderBottomLeftRadius:'100px', 
     alignItems: 'center',
      width: '54%',
      minHeight:"60px",
      maxHeight: '70px', 
      backgroundColor:'#FFFFFF',

  },
  '@media (max-width: 974px)': {
    border: "2px solid #434343",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
       borderRadius: "60px", 
       width: '84%',
       maxHeight: '70px', 
       minHeight:"60px",
       marginBottom:'20px',
       backgroundColor:'#FFFFFF',

  
  },'@media (max-width: 918px)': {
    border: "2px solid #434343",
    display: 'flex',
     justifyContent: 'center',
      alignItems: 'center',
       borderRadius: "60px", 
       width: '84%',
       maxHeight: '70px',
       minHeight:"60px",
       marginTop:'40px',
      marginBottom:'15px',
      backgroundColor:'#FFFFFF',
     
  },
  '@media (max-width: 858px)': {
    border: "2px solid #434343",
    display: 'flex',
     justifyContent: 'center',
     borderRadius: "50px", 
     alignItems: 'center',
       width: '84%',
       minHeight:"60px",
       maxHeight: '70px',
       marginTop:'35px',
      marginBottom:'15px',
      backgroundColor:'#FFFFFF',

  },'@media (max-width: 768px)': {
    border: "2px solid #434343",
    justifyContent: 'center',
    display: 'flex',
      alignItems: 'center',
       borderRadius: "50px", 
       minHeight:"60px",
       width: '74%',
       maxHeight: '70px',
       marginTop:'30px',
       marginBottom:'15px',
       backgroundColor:'#FFFFFF',

  },'@media (max-width: 626px)': {
    border: "2px solid #434343",
    display: 'flex',
     justifyContent: 'center',
      alignItems: 'center',
       borderRadius: "40px", 
       minHeight:"55px",
       width: '74%',
       maxHeight: '70px', 
       marginTop:'30px',
       marginBottom:'15px',
       backgroundColor:'#FFFFFF',

  },
  '@media (max-width: 468px)': {
    border: "2px solid #434343",
    display: 'flex',
     justifyContent: 'center',
      alignItems: 'center',
       borderRadius: "40px", 
       width: '84%',
       maxHeight: '70px', 
       minHeight:"55px",
      marginBottom:'10px',
      marginTop:'25px',
      backgroundColor:'#FFFFFF',
      
  
  },'@media (max-width: 386px)': {
    border: "2px solid #434343",
    display: 'flex',
     justifyContent: 'center',
      alignItems: 'center',
       borderRadius: "30px", 
       width: '84%',
       maxHeight: '70px', 
       minHeight:"60px",
       marginTop:'25px',
       marginBottom:'18px',
       backgroundColor:'#FFFFFF',


  },
 
})


const StyleJarvysParentBoxInput=styled(Box)({
  width: '100%', display: 'flex', justifyContent: 'center',marginBottom:'50px',
  '@media (max-width: 1034px)': {
    width: '100%', display: 'flex', justifyContent: 'center',marginBottom:'50px',


  },
 '@media (max-width: 974px)': {
    width: '100%', display: 'flex', justifyContent: 'center',
    flexDirection:'column',marginBottom:'50px',
    alignItems:'center',


  },
  '@media (max-width: 918px)': {
    width: '100%', display: 'flex', justifyContent: 'center',
  alignItems:'center',
    flexDirection:'column',marginBottom:'50px',


  },'@media (max-width: 858px)': {
    width: '100%', display: 'flex', justifyContent: 'center',
  alignItems:'center',marginBottom:'50px',
    flexDirection:'column',


  },'@media (max-width: 768px)': {
    width: '100%', display: 'flex', justifyContent: 'center',
    flexDirection:'column',
  alignItems:'center',


  },
  '@media (max-width: 626px)': {
    width: '100%', display: 'flex', justifyContent: 'center',
  alignItems:'center',
  
  flexDirection:'column',
  },
  '@media (max-width: 468px)': {
    width: '100%', display: 'flex', justifyContent: 'center',
    flexDirection:'column',
    alignItems:'center',

  
  },'@media (max-width: 386px)': {
    alignItems:'center',
    width: '100%', display: 'flex', justifyContent: 'center',
    flexDirection:'column',
  },
 
})

const StyleJarvysBoxButton=styled(Button)({

   color: '#FFFFFF',
                    borderTopRightRadius:'100px',
                    borderBottomRightRadius:'100px',
                    fontFamily: 'Open Sans',
                    padding: '6px 50px',
                    cursor: 'pointer',
  '@media (max-width: 1034px)': {
    color: '#FFFFFF',
    borderTopRightRadius:'100px',
    borderBottomRightRadius:'100px',
    fontFamily: 'Open Sans',
    padding: '6px 50px',
    cursor: 'pointer'


  },
 '@media (max-width: 974px)': {
   borderRadius:'100px',
   padding: '20px 50px',

  },
  '@media (max-width: 918px)': {
    borderRadius:'100px',
    padding: '20px 50px',



  },'@media (max-width: 858px)': {
    borderRadius:'100px',
    padding: '20px 50px',

  },'@media (max-width: 768px)': {
    borderRadius:'100px',
    padding: '20px 60px',



  },
  '@media (max-width: 626px)': {
    borderRadius:'100px',
    padding: '20px 130px',

  },
  '@media (max-width: 468px)': {
    borderRadius:'100px',
    padding: '20px 120px',

  
  },'@media (max-width: 386px)': {
    borderRadius:'100px',
    padding: '20px 100px',

  },
 
})






// Customizable Area End 