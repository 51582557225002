import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Button,
  Box,

} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const Closebutton = require("./button_close.svg").default
const Centerdatailogo = require("./centerdataiiconmob.svg").default
const userprofile = require("./uprofileicon.svg").default
const googleicon = require("./googleicon.svg").default


import { styled } from '@mui/material/styles';
interface NavbarProps {
  handlelogin: () => void;
  handlesignup: () => void;
  isActive: boolean
}

const Navbar: React.FC<NavbarProps> = ({ isActive, handlelogin, handlesignup }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handlesignupdrawer = () => {

    handlesignup()
    handleDrawerClose()
  }

  const handlelogindrawer = () => {

    handlelogin()
    handleDrawerClose()
  }

  return (
    <Box>
      <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: isActive ? 'transparent' : '#fffff' }}>
        <Toolbar >
          <IconButton
            edge="start"
            color="success"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{
            display: { xs: "none", sm: "none", md: "flex", lg: "flex" }
          }} justifyContent={'start'} gap={3}>


            <StyledMenuTypo isActive={isActive} >About DatAI</StyledMenuTypo>

            <Box>
              <StyledMenuTypo isActive={isActive}>Services</StyledMenuTypo>
            </Box>
            <Box>
              <StyledMenuTypo isActive={isActive}>Contact Us</StyledMenuTypo>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexGrow={1} margin={2}>
            <Box>
              <img src={Centerdatailogo} alt="datailogo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex", lg: "flex" }
            }} justifyContent={'flex-end'}
          >

            <Box display={'flex'} gap={2}>


              <StyledMenuTypo onClick={handlelogin} isActive={isActive}
              >
                Login
              </StyledMenuTypo>
              <StyledMenuTypo isActive={isActive} onClick={handlesignup}
              >
                Sign Up
              </StyledMenuTypo>
            </Box>

          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: "100%",
            height: "auto",
          },
        }}
      >

        <Box padding={'24px'} >
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <Box>

              <img src={Closebutton} alt="close_icon" onClick={handleDrawerClose} />
            </Box>
            <Box>

              <img src={Centerdatailogo} alt="datailogo" />
            </Box>
            <Box>

              <img src={userprofile} alt="user-profile_icon" />
            </Box>

          </Box>
          <Box marginTop={2} display={'flex'} justifyContent={'center'} flexDirection={'column'} gap={2}>

            <Box>
              <Button onClick={handlesignupdrawer}
                data-test-id="goToHomeButton"
                data-testid="askToJarvysButton"
                style={{
                  color: 'black',
                  borderRadius: '8px',
                  fontFamily: 'Open Sans',
                  padding: '10px 16px',
                  cursor: 'pointer',
                  width: '100%',
                  backgroundColor: '#fffff',
                  textTransform: 'none',
                  border: '1px solid #B3B3B3',
                }}>Sign Up</Button>
            </Box>


            <Box>
              <Button
                data-test-id="goToHomeButton"
                data-testid="askToJarvysButton"
                style={{
                  color: 'black',
                  borderRadius: '8px',
                  fontFamily: 'Open Sans',
                  padding: '10px 16px',
                  cursor: 'pointer',
                  width: '100%',
                  backgroundColor: '#fffff',
                  textTransform: 'none',
                  border: '1px solid #B3B3B3',

                }}
                startIcon={
                  <img
                    src={googleicon}
                    alt="custom icon"
                  />
                }
              >Register with Google</Button>
            </Box>
            <Box>
              <Button onClick={handlelogindrawer}
                data-test-id="goToHomeButton"
                data-testid="askToJarvysButton"
                style={{
                  color: '#fffff',
                  borderRadius: '8px',
                  fontFamily: 'Open Sans',
                  padding: '10px 16px',
                  cursor: 'pointer',
                  width: '100%',
                  backgroundColor: 'black',
                  textTransform: 'none'
                }}  >Login</Button>
            </Box>

          </Box>

        </Box>
      </Drawer>
    </Box>
  );
};

export default Navbar;
const StyledMenuTypo = styled(Typography)<{ isActive: boolean }>(({ isActive }) => ({

  fontFamily: 'Open Sans',
  margin: "2px",
  cursor: 'pointer',
  fontWeight: 400,
  fontSize: '18px',
  color: isActive ? '#fffff' : '#000000',





}));

