import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import  { ChangeEvent } from 'react';
import { getStorageData, setStorageData } from "framework/src/Utilities";
const DOMPurify = require('dompurify');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  values:string[]
  isOpenSignUpForm:boolean
  isOpenLogInForm:boolean
  isOpenForgetPasswordForm:boolean;
  isOpenForgetPasswordOtpForm:boolean;
  userMessage:string;
  userquestion:string
  userMessageResponse:string;
  isBackgroundVideoAvailable:boolean
  jarvysmodelopenlanding:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.closeSignUpFormMessage),
      getName(MessageEnum.openLogInFormRegistrationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.hasVideoMessage),
    ];

    this.state = {
      values:[],
      isOpenSignUpForm:false,
      isOpenLogInForm:false,
      userMessage:"",
      userMessageResponse:"",
      userquestion:"",
      isBackgroundVideoAvailable:false,
      isOpenForgetPasswordForm:false,
      isOpenForgetPasswordOtpForm:false,
      jarvysmodelopenlanding:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveResponseHandler(from,message)
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.closeSignUpFormMessage) === message.id) {
      this.handleCloseSignUpForm() 
    }
    if (getName(MessageEnum.openLogInFormRegistrationMessage) === message.id) {
      this.handleOpenLogInFromRegistration() 
    }
    if(getName(MessageEnum.hasVideoMessage)=== message.id){
      this.setState({isBackgroundVideoAvailable:true})
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    let landingQues= await getStorageData('landinguserquestion') 
    let prevchats= await getStorageData('chats') 
    if (prevchats){
      let chats  = JSON.parse(prevchats)
      chats.push(landingQues)
      let resp=await getStorageData('userresponcelanding')
      chats.push(resp)
      this.setState({values:chats})
    }
  }

  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.userquestion !== this.state.userquestion) {
      let chats=this.state.values
      if(this.state.userquestion!==null){
        
        chats.push(this.state.userquestion)
        this.setState({values:chats})
        setStorageData('chats',JSON.stringify(chats))
      }
    }
    if (this.state.values.length > 6 ) {
      let chats  = this.state.values
  chats.splice(0,1)
  this.setState({values:chats})
    }
    
  }

  getMessageResponseApiCallId:string="";
  getMessageResponseApiCallIdLanding: string="";
  async receiveResponseHandler(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );      
      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId ===this.getMessageResponseApiCallId ){
          this.responcehandler('<p>'+responseJson.content.replaceAll('\n', '<br>')+'<p>') 
        }
       
      }
    }
  }


handleGoToBrainstrome(){
  this.props.navigation.navigate("Brainstromwithjarvis");
}
  
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleOpenSignUpForm(){
    this.setState({isOpenSignUpForm:true})
  }

  handleCloseSignUpForm(){
    this.setState({isOpenSignUpForm:false})
  }
  handleuserQuestion(userMessagess:string){
    this.setState({userquestion:userMessagess})
    this.setState({userMessage:""})
  }

  handleOpenLogInForm(){
    this.setState({isOpenLogInForm:true})
  }

  handleCloseLogInForm(){
    this.setState({isOpenLogInForm:false})
  }
  handleOpenForgetPassword(){
    this.setState({isOpenLogInForm:false, isOpenForgetPasswordForm:true})
  }
  handleCloseForgetPasswordForm(){
    this.setState({isOpenForgetPasswordForm:false})
  }
  handleOpenRegistrationFromLogIn(){
    this.setState({isOpenLogInForm:false,isOpenSignUpForm:true})
  }
  handleOpenLogInFromRegistration(){
    this.setState({isOpenLogInForm:true,isOpenSignUpForm:false})
  }

  handleUserMessageChange(event:ChangeEvent<HTMLInputElement>){
    this.setState({userMessage:event.target.value})
  }
  askToJarvys(){
   
      this.setState({jarvysmodelopenlanding:true})

    this.handleuserQuestion(this.state.userMessage)
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };  
    const endPoint=`${configJSON.getMessageResponseFromJarvysEndPont}?question=${this.state.userMessage}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMessageResponseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  responcehandler(content:string) {
    let chats=this.state.values
      chats.push(content)
      this.setState({values:chats, userMessageResponse: content})
    setStorageData('chats',JSON.stringify(chats))
    this.props.navigation.navigate("Brainstromwithjarvis");
  }
 
  handleclosejarvysmodel(){
    this.setState({jarvysmodelopenlanding:false})
  }
  

  // Customizable Area End
}
