// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Grid,
} from '@mui/material'
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import CampaignCard from './components/CampaignCard'
import {StyledFormControl} from "../../../components/src/StyledComponents"
import BreadcrumbNavigationPage from "../../../blocks/breadcrumbnavigation/src/BreadcrumbNavigationPage.web"
import SearchIcon from '@mui/icons-material/Search';
import ContentManagementCampaignsController, {
  Props,
} from "./ContentManagementCampaignsController";

export default class ContentManagementCampaigns extends ContentManagementCampaignsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <BreadcrumbNavigationPage navigation={this.props.navigation}>
        <Box sx={{display:"flex", flexDirection:"column", gap:"24px", padding:"32px",}}>
          <StyledHeadingContainer>
            <Typography sx={webStyle.pageHeading}>
              My Campaigns
            </Typography>
            <Button data-testid='createCampaignButton' sx={webStyle.newCampaignButton} endIcon={<AddIcon sx={{fontSize:"16px"}}/>} onClick={this.createCampaigns.bind(this)}>
              New Campaign
            </Button>
          </StyledHeadingContainer>
          <StyledSearchContainer>
            <StyledSearchTextField
            data-testid='searchInput'
            onChange={this.handleSearch.bind(this)}
             placeholder="Search"
             fullWidth
             InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            />
            <StyledFormControl sx={{  maxWidth: 202 }}>   
            <Typography>Sort by</Typography>           
              <StyledSelect
                fullWidth
              >
              <MenuItem value="A-Z">{'Name: (A - Z)'}</MenuItem>
              <MenuItem value={10}>{'Name: (Z - A)'}</MenuItem>
              <MenuItem value={20}>{'Date Created: Low > High'}</MenuItem>
              <MenuItem value={30}>{'Date Created: High > Low'}</MenuItem>
              </StyledSelect>
            </StyledFormControl>
            
          </StyledSearchContainer>
          <Grid container spacing={3} data-testid='CampaignCardContainer'>
            {this.state.campaigns.map((campaign,index) =>(<Grid item key={index}>
              <CampaignCard data-testid={`CampaignCard${index}`} campaign={campaign} onDelete={this.deleteCampaigns.bind(this)} onRename={this.editCampaigns.bind(this)} onClick={()=>{}}/>
            </Grid>))}
          </Grid>
        </Box> 
      </BreadcrumbNavigationPage>
    );
  }
}

const StyledHeadingContainer = styled(Box)({
  display: "flex", 
  justifyContent:"space-between",
  alignItems:"center",
  width:"100%",
  '@media (max-width: 646px)': {
   flexDirection:"column",
   alignItems:"start",
   justifyContent:"center",
   gap:"12px"
  },
  '@media (max-width: 600px)': {
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
   },
   '@media (max-width: 463px)': {
    flexDirection:"column",
    alignItems:"start",
    justifyContent:"start",
    gap:"12px"
   },
})

const StyledSearchContainer = styled(Box)({
  display: "flex", 
  justifyContent:"space-between",
  alignItems:"end",
  width:"100%",
  '@media (max-width: 754px)': {
   flexDirection:"column",
   alignItems:"start",
   justifyContent:"center",
   gap:"12px"
  },
  '@media (max-width: 600px)': {
    flexDirection:"row",
    alignItems:"end",
    justifyContent:"space-between",
   },
   '@media (max-width: 463px)': {
    flexDirection:"column",
    alignItems:"start",
    justifyContent:"start",
    gap:"12px"
   },
})

const StyledSearchTextField = styled(TextField) ({
   width:"calc(100% - 234px)",
  '& .MuiOutlinedInput-root': {
   fontFamily: "Open Sans",
    height: "48px",
    background:"white",
    '& fieldset': {
      border:"1px solid #DDDDDD",
      borderRadius:"100px",
    },
    '&.Mui-focused fieldset': {
      border:"1px solid #000000",
    },
    '&:hover fieldset': {
      borderColor: '#797979',
    },
    '&.Mui-focused:hover fieldset': {
      borderColor: '#000000'
    },
  },
  '& .MuiInputBase-input': {
    height: '100%',
    width:"100%" 
  },
  '@media (max-width: 854px)': {
    width:"calc(100% - 170px)"
  },
  '@media (max-width: 754px)': {
    width:"100%"
  },
  '@media (max-width: 600px)': {
    width:"calc(100% - 170px)"
   },
   '@media (max-width: 463px)': {
    width:"100%"
   },
});

const StyledSelect= styled(Select) ({
  height:"48px",
  width:'202px',
  '& fieldset': {
    border:"1px solid #D9D9D9",
    borderRadius:"8px"
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000000',
    borderSize:"1px",
  },
  '&.Mui-focused:hover fieldset': {
    borderColor: '#000000'
  },
  '@media (max-width: 854px)': {
    width:'clamp(150px, 10vw, 202px)'
  },
  '@media (max-width: 754px)': {
    width:"202px"
  },
  '@media (max-width: 600px)': {
    width:"150px"
  },
  '@media (max-width: 463px)': {
    width:"202px"
   },
})
const webStyle ={
  pageHeading:{
    fontSize:"32px",
    fontWeight:600,
    fontFamily:"Open Sans",
    color:"#4D4D4D"
  },
  newCampaignButton:{
    textTransform: 'none',
    color: "#FFFFFF",
    ontSize:"16px",
    fontWeight:600,
    fontFamily:"Open Sans",
    padding:'10px',
    background:'#000000',
    borderRadius:"8px",
    "&:hover":{
        background:'#000000',
    }
  }
}
// Customizable Area End
