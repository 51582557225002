// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

interface MessageArray {
  id: number;
  message: string;
  isUser: boolean;
}

interface RespData {
  warning:string;
  id:string;
  object:string;
  created:number;
  model:string;
  choices:[{"text":" Hello there! How can I help you?","index":0,"logprobs":null,"finish_reason":"stop"}];
  usage:{"prompt_tokens":7,"completion_tokens":9,"total_tokens":16}
}

interface IMessage {
  id: number;
  message: string;
  isUser: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  messages:Array<MessageArray>;
  input:string;
  isLoading:boolean;
  data:string;
  isModalOpen: boolean
  isChatPage: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatgptController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  openApiID: string = "";
 

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
   
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start

      messages:[],
      input:'',
      isLoading:false,
      isModalOpen: false,
      isChatPage: false,
      data:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        if (apiRequestCallId === this.openApiID) {
         this.getAllSuccessFunctionCallBack(responseJson);
      }
        
    
    }
    // Customizable Area End

  }

  // Customizable Area Start

  getAllSuccessFunctionCallBack = ( responseJson: RespData) => {
    
  this.setState({ data: responseJson.choices[0].text });

  const aiMessage = {
    id: this.state.messages.length + 2,
    message: this.state.data,
    isUser: false,
  };
  this.setState((prevState) => ({
    messages: [...prevState.messages, aiMessage],
    isLoading: false,
  }));
}

newState=(e: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({ input: e?.target?.value })
}

tempState=(text:string)=>{
  this.setState({input:text})}

sendMessage = async () => {
  if (this.state.input?.trim().length === 0) return;
  const message: IMessage = {
    id: this.state.messages.length + 1,
    message: this.state.input,
    isUser: true,
  };

  this.setState((prevState) => ({
    messages: [...prevState.messages, message],
    input: "",
    isLoading: true,
  }));

  try {
   this.openApiCall(this.state.input)
  
  } catch (error) {
    this.setState({ isLoading: false });
  }
};

secondScreen=()=>{
  this.props.navigation.navigate('Chatgpt')
}
 sendMessages = () => {
  if (this.state.input?.trim().length === 0) return;

  let message = {
    id: this.state.messages.length + 1,
    message: this.state.input,
    isUser: true,
  };
  this.setState({
        messages: [...this.state.messages,message],
        input: "",
        isLoading:true
      });
      
  try {
  this.openApiCall(this.state.input)
  } catch (error) {
   this.setState({
    isLoading:false
  });
  }
};


  openApiCall = (message: string) => {
 const header= {
      Authorization: `Bearer ${"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"}`,
      "Content-Type": "application/json",
    }
    const prompt = `User: ${message}\nAI:`;

    const data = {
    prompt,
    model: "text-davinci-003",
    temperature: 0.7,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "https://api.openai.com/v1/completions"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"    
      );
      
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloseModal = () =>{
    this.setState({ isModalOpen: false})
  }

  handleOpenModal = () =>{
    this.setState({ isModalOpen: true})
  }

  handleChatPage = () =>{
    this.setState({ isChatPage : true })
  }

  handleChatPageBack = () =>{
    this.setState({ isChatPage : false })
  }
  // Customizable Area End
}

// Customizable Area End
